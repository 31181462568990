import { IOnHold, ProForma } from '../services/data/proforma';
import { PropertyStatusType } from '../services/data/propertyStatusType';
import { ListingComps } from '../services/data/listingComps';

export interface IListingData {
  CompsMapCenter?: any;
  CompsMapZoom?: any;
  isChildRow?: boolean;
  Beds?: number;
  LivingAreaSqFt?: number;
  YearBuilt?: number;
  BathsCount?: number;
  FullBaths?: number;
  HalfBaths?: number;
  isListingManuallyUpdated?: boolean;
  isHeaderRow?: boolean;
  groupCount?: number;
  rejectionReasons?: {
    updated: boolean,
    newValue: string
  };
}

export const MissingData = {
  LivingAreaSqFt: {
    required: true,
    exceptionValidator: '(living area)|(sqft)|(sq ft)'
  },
  YearBuilt: {
    required: true,
    exceptionValidator: 'year built'
  },
  BathsCount: {
    required: true,
    exceptionValidator: 'baths'
  },
  Beds: {
    required: true,
    exceptionValidator:  'bedrooms'
  }
};

export class ProFormaModel {
  isDirty = false;
  userInputProforma: ProForma = null;
  originalUserInputProforma: ProForma = null;
  proforma: ProForma = null;
  originalProforma: ProForma = null;
  initialProforma: ProForma = null;
  selectedComparableIds: any[] = [];
  selectedRentComparableIds: string[] = [];
  selectedSaleComparableIds: string[] = [];
  selectedActiveComparableIds: string[] = [];
  selectedComparableRowsCopy: ListingComps[] = [];

  listingStatus: PropertyStatusType = null;
  messageOnSave: string = null;
  reasonFewComps: string = null;

  currentPrice: number = null;

  listingData: IListingData = {};

  workFlowNote: {
    type:'comment' | 'concession' | 'preInspection';
    newValue: string;
    oldValue: string;
  } = null;

  sendToTransactionManager: boolean = null;
  pendingEvents: any = null;
  onHold: IOnHold = null;
  automaticUpdate = false;

  isEdited() {
    return !this.isNotEdited();
  }

  isNotEdited() {
    return this.proforma.targetConditionScore === null &&
      this.proforma.monthlyRentAmount === null &&
      this.proforma.vacancyAllowanceAmount === null &&
      this.proforma.creditLossAmount === null &&
      this.proforma.taxesAmount === null &&
      this.proforma.propertyManagementFeesAmount === null &&
      this.proforma.maintenanceTurnoverAmount === null &&
      this.proforma.hoaAmount === null &&
      this.proforma.leasingCostsAmount === null &&
      this.proforma.priceOfferAmount === null &&
      this.proforma.repairBudgetAmount === null &&
      this.proforma.acquisitionCostAmount === null &&
      this.proforma.stabilizationFeeAmount === null;
  }
}
