import { ListingProperty } from 'src/app/services/data/listingPropety';

import * as _ from 'lodash';
import * as JsUtils from 'src/app/utils/jsUtils';
import { UserRole } from 'src/app/roles-permissions/types';


export interface DetailsDef {
  fieldId: string;
  label: string;
  gridLabel: string;
  formatter: any;
  show: boolean;
  value: any;
  display: string;
  editable?: string;
}

export interface DetailsGroupDef {
  show: boolean;
  expanded: boolean;
  defaultExpanded: boolean;
  title: string;
  detailsDef: DetailsDef[];
  roles?: UserRole[];
}


export const ListingColumns: any = {
  AOListingID: { fieldId: "AOListingID", label: "Maestro ID", gridLabel: null, formatter: null, show: true, value: null, display: null },
  Address: { fieldId: "Address", label: "Address", gridLabel: null, formatter: null, show: true, value: null, display: null },
  Unit: { fieldId: "Unit", label: "Unit", gridLabel: null, formatter: null, show: true, value: null, display: null },
  City: { fieldId: "City", label: "City", gridLabel: null, formatter: null, show: true, value: null, display: null },
  State: { fieldId: "State", label: "State", gridLabel: null, formatter: null, show: true, value: null, display: null },
  ZipCode: { fieldId: "ZipCode", label: "ZipCode", gridLabel: null, formatter: null, show: true, value: null, display: null },
  Region: { fieldId: "Region", label: "Region", gridLabel: null, formatter: null, show: true, value: null, display: null },
  FIPS: { fieldId: "FIPS", label: "FIPS", gridLabel: null, formatter: null, show: true, value: null, display: null },
  CountyName: { fieldId: "CountyName", label: "County Name", gridLabel: null, formatter: null, show: true, value: null, display: null },
  CensusTract: { fieldId: "CensusTract", label: "Census Tract", gridLabel: null, formatter: null, show: true, value: null, display: null },
  Latitude: { fieldId: "Latitude", label: "Latitude", gridLabel: null, formatter: null, show: true, value: null, display: null },
  Longitude: { fieldId: "Longitude", label: "Longitude", gridLabel: null, formatter: null, show: true, value: null, display: null },

  PropertyType: { fieldId: "PropertyType", label: "Property Type", gridLabel: null, formatter: null, show: true, value: null, display: null },
  PropertySubtype: { fieldId: "PropertySubtype", label: "Property Subtype", gridLabel: null, formatter: null, show: true, value: null, display: null },
  Beds: { fieldId: "Beds", label: "Beds", gridLabel: null, formatter: null, show: true, value: null, display: null },
  FullBaths: { fieldId: "FullBaths", label: "Full Baths", gridLabel: null, formatter: null, show: true, value: null, display: null },
  HalfBaths: { fieldId: "HalfBaths", label: "Half Baths", gridLabel: null, formatter: null, show: true, value: null, display: null },
  BathsCount: { fieldId: "BathsCount", label: "Total Baths", gridLabel: null, formatter: null, show: true, value: null, display: null },
  YearBuilt: { fieldId: "YearBuilt", label: "Year Built", gridLabel: null, formatter: null, show: true, value: null, display: null },
  LivingAreaSqFt: { fieldId: "LivingAreaSqFt", label: "Living Area SqFt", gridLabel: null, formatter: null, show: true, value: null, display: null },
  LotSizeAcres: { fieldId: "LotSizeAcres", label: "Lot Size Acres", gridLabel: null, formatter: (data) => {
    return data ? data.toFixed(2) : '-';
   } , show: true, value: null, display: null },

  AOPropertyID: { fieldId: "AOPropertyID", label: "AO Property ID", gridLabel: null, formatter: null, show: true, value: null, display: null },
  ListingStatus: { fieldId: "ListingStatus", label: "Listing Status", gridLabel: null, formatter: null, show: true, value: null, display: null },
  StatusDate: { fieldId: "StatusDate", label: "Status Date", gridLabel: null, formatter: JsUtils.getDateString, show: true, value: null, display: null },
  CurrentPrice: { fieldId: "CurrentPrice", label: "Current Price", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  OrigPrice: { fieldId: "OrigPrice", label: "Original Listing Price", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  ListingDate: { fieldId: "ListingDate", label: "Listing Date", gridLabel: null, formatter: JsUtils.getDateString, show: true, value: null, display: null },
  NumberPrice: { fieldId: "nbPrice", label: "Number of Price Change",  gridLabel: null, formatter: null, show: true, value: null, display: null },
  TotalChangePrice: { fieldId: "CurrentPrice", fieldId2: "OrigPrice", label: "Total Change in Price",  gridLabel: null, formatter: null, show: true, value: null, display: null, difference: true },

  RealDaysOnMarket: { fieldId: "RealDaysOnMarket", label: "Days On Market", gridLabel: null, formatter: null, show: true, value: null, display: null },
  ListingAgentName: { fieldId: "ListingAgentName", label: "Listing Agent Name", gridLabel: null, formatter: null, show: true, value: null, display: null, editable: 'text' },
  ListingBrokerName: { fieldId: "ListingBrokerName", label: "Listing Broker Name", gridLabel: null, formatter: null, show: true, value: null, display: null, editable: 'text' },
  ListingAgentPhone: { fieldId: "ListingAgentPhone", label: "Listing Agent Phone", gridLabel: null, formatter: null, show: true, value: null, display: null, editable: 'text' },
  ListingAgentEmail: { fieldId: "ListingAgentEmail", label: "Listing Agent Email", gridLabel: null, formatter: null, show: true, value: null, display: null, editable: 'text' },
  PublicRemarks: { fieldId: "PublicRemarks", label: "Public Remarks", gridLabel: null, formatter: null, show: true, value: null, display: null },
  PrivateRemarks: { fieldId: "PrivateRemarks", label: "Private Remarks", gridLabel: null, formatter: null, show: true, value: null, display: null, editable: 'textarea' },
  MLSName: { fieldId: "MLSName", label: "MLS Name", gridLabel: null, formatter: null, show: true, value: null, display: null },
  MLSListingID: { fieldId: "MLSListingID", label: "MLS Listing ID", gridLabel: null, formatter: null, show: true, value: null, display: null },
  LastUpdate: { fieldId: "LastUpdate", label: "Last Update", gridLabel: null, formatter: JsUtils.getDateString, show: true, value: null, display: null },


  EstimatedRent: { fieldId: "EstimatedRent", label: "Estimated Rent", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  EstimatedRentStdDev: { fieldId: "EstimatedRentStdDev", label: "Estimated Rent StdDev", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  HOADues: { fieldId: "HOADues", label: "HOA Dues", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  PropertyTaxAppraisal: { fieldId: "PropertyTaxAppraisal", label: "Property Tax Appraisal", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  CurrentPropertyTax: { fieldId: "CurrentPropertyTax", label: "Current Property Tax", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  PropertyTaxYear: { fieldId: "PropertyTaxYear", label: "Property Tax Year", gridLabel: null, formatter: null, show: true, value: null, display: null },
  SFHA: { fieldId: "SFHA", label: "SFHA", gridLabel: null, formatter: null, show: true, value: null, display: null },
  OccupancyRate: { fieldId: "OccupancyRate", label: "OccupancyRate", gridLabel: null, formatter: JsUtils.formatPercent2, show: true, value: null, display: null },
  CreditLoss: { fieldId: "CreditLoss", label: "CreditLoss", gridLabel: null, formatter: JsUtils.formatPercent2, show: true, value: null, display: null },
  ManagementFee: { fieldId: "ManagementFee", label: "Management Fee", gridLabel: null, formatter: JsUtils.formatPercent2, show: true, value: null, display: null },

  Maintenance1: { fieldId: "Maintenance1", label: "Maintenance 1", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  Maintenance2: { fieldId: "Maintenance2", label: "Maintenance 2", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  Maintenance3: { fieldId: "Maintenance3", label: "Maintenance 3", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  Maintenance4: { fieldId: "Maintenance4", label: "Maintenance 4", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  Maintenance5: { fieldId: "Maintenance5", label: "Maintenance 5", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  Maintenance6: { fieldId: "Maintenance6", label: "Maintenance 6", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },

  LeasingCost: { fieldId: "LeasingCost", label: "Leasing Cost", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  LeaseFee: { fieldId: "LeaseFee", label: "Lease Fee", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  PropertyTaxRate: { fieldId: "PropertyTaxRate", label: "Property Tax Rate", gridLabel: null, formatter: JsUtils.formatPercent2, show: true, value: null, display: null },
  BidPrice: { fieldId: "priceOfferAmount", label: "Bid Price", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  BidPctofAsk: { fieldId: "BidPctofAsk", label: "Bid Pct of Ask", gridLabel: null, formatter: JsUtils.formatPercent2, show: true, value: null, display: null },
  ClosingCost: { fieldId: "ClosingCost", label: "Closing Cost", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  AcquisitionCost: { fieldId: "AcquisitionCost", label: "Acquisition Cost", gridLabel: null, formatter: JsUtils.formatPercent2, show: true, value: null, display: null },
  Concessions: { fieldId: "Concessions", label: "Concessions", gridLabel: null, formatter: JsUtils.formatPercent2, show: true, value: null, display: null },
  OtherIncome: { fieldId: "OtherIncome", label: "Other Income", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  ConditionScore: { fieldId: "ConditionScore", label: "Condition Score", gridLabel: null, formatter: null, show: true, value: null, display: null },
  Occupant: { fieldId: "Occupant", label: "Occupant", gridLabel: null, formatter: null, show: true, value: null, display: null },
  OwnerName: { fieldId: "OwnerName", label: "Owner Name", gridLabel: null, formatter: null, show: true, value: null, display: null, editable: 'text' },
  SaleAVM: { fieldId: "SaleAVM", label: "Sale AVM", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  SaleAVMStdDev: { fieldId: "SaleAVMStdDev", label: "Sale AVMStdDev", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  LastSaleDate: { fieldId: "LastSaleDate", label: "Last Sale Date", gridLabel: null, formatter: JsUtils.getDateString, show: true, value: null, display: null },
  LastSalePrice: { fieldId: "LastSalePrice", label: "Last Sale Price", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  LastRentDate: { fieldId: "LastRentDate", label: "Last Rent Date", gridLabel: null, formatter: JsUtils.getDateString, show: true, value: null, display: null },
  LastRent: { fieldId: "LastRent", label: "Last Rent", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  Zestimate: { fieldId: "Zestimate", label: "Zestimate", gridLabel: null, formatter: null, show: true, value: null, display: null },
  MedianIncome: { fieldId: "MedianIncome", label: "Median Income", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },

  SchoolScoreElementary: { fieldId: "SchoolScoreElementary", label: "School Score Elementary", gridLabel: null, formatter: null, show: true, value: null, display: null },
  SchoolScoreMiddle: { fieldId: "SchoolScoreMiddle", label: "School Score Middle", gridLabel: null, formatter: null, show: true, value: null, display: null },
  SchoolScoreHigh: { fieldId: "SchoolScoreHigh", label: "School Score High", gridLabel: null, formatter: null, show: true, value: null, display: null },
  CrimeScore: { fieldId: "CrimeScore", label: "Crime Score", gridLabel: null, formatter: null, show: true, value: null, display: null },
  ListingURL: { fieldId: "ListingURL", label: "Listing URL", gridLabel: null, formatter: null, show: true, value: null, display: null },
  MaxPropertyTaxValue: { fieldId: "MaxPropertyTaxValue", label: "Max Property Tax Value", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  InsuranceRate: { fieldId: "InsuranceRate", label: "Insurance Rate", gridLabel: null, formatter: JsUtils.formatPercent2, show: true, value: null, display: null },
  InitialCarryPeriod: { fieldId: "InitialCarryPeriod", label: "Initial Carry Period", gridLabel: null, formatter: null, show: true, value: null, display: null },
  StabilizationFee : { fieldId: "StabilizationFee", label: "Stabilization Fee ", gridLabel: null, formatter: JsUtils.formatPercent2, show: true, value: null, display: null },
  TargetConditionScore: { fieldId: "TargetConditionScore", label: "Target Condition Score", gridLabel: null, formatter: null, show: true, value: null, display: null },
  MinRehabCost: { fieldId: "MinRehabCost", label: "Min Rehab Cost", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RentCondition1: { fieldId: "RentCondition1", label: "Rent Condition 1", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RehabCost12: { fieldId: "RehabCost12", label: "Rehab Cost 1-2", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RentCondition2: { fieldId: "RentCondition2", label: "Rent Condition 2", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RehabCost23: { fieldId: "RehabCost23", label: "Rehab Cost 2-3", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RentCondition3: { fieldId: "RentCondition3", label: "Rent Condition 3", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RehabCost34: { fieldId: "RehabCost34", label: "Rehab Cost 3-4", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RentCondition4: { fieldId: "RentCondition4", label: "Rent Condition 4", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RehabCost45: { fieldId: "RehabCost45", label: "Rehab Cost 4-5", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RentCondition5: { fieldId: "RentCondition5", label: "Rent Condition 5", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RehabCost56: { fieldId: "RehabCost56", label: "Rehab Cost 5-6", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  RentCondition6: { fieldId: "RentCondition6", label: "Rent Condition 6", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  Section8: { fieldId: "Section8", label: "Section 8", gridLabel: null, formatter: null, show: true, value: null, display: null },
  RecurringCapEx: { fieldId: "RecurringCapEx", label: "Recurring Capex", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  Utilities: { fieldId: "Utilities", label: "Utilities", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },
  StartupFee: { fieldId: "StartupFee", label: "Startup Fee", gridLabel: null, formatter: JsUtils.formatCurrency, show: true, value: null, display: null },



  TMStatus: { fieldId: "TMStatus", label: "Status", gridLabel: null, formatter: null, show: true, value: null, display: null },
  SubStatus: { fieldId: "subStatus", label: "Substatus", gridLabel: null, formatter: null, show: true, value: null, display: null },
  InspectionStatus: { fieldId: "inspectionStatus", label: "Inspection Status", gridLabel: null, formatter: null, show: true, value: null, display: null },

  AiAlm: { fieldId: "AiAlm", label: "AI-ALM", gridLabel: null, formatter: null, show: true, value: null, display: null },
  BuyBoxV2: { fieldId: "BuyBoxV2", label: "Buy Box Classification", gridLabel: null, formatter: null, show: true, value: null, display: null }
};

export const PDFDetailsColumns: any = {
  originalRehabBudget: { fieldId: "uwOriginalRehabBudget", label: "Original Est. Rehab", formatter: JsUtils.formatCurrency },
  rehabBudget: { fieldId: "uwRehabBudget", label: "Post-Inspection Est. Rehab", formatter: JsUtils.formatCurrency },
  capRate: { fieldId: "capRate", label: "Updated Cap Rate", formatter: JsUtils.formatPercent2 },
  netYield: { fieldId: "netYield", label: "Net Yield", formatter: JsUtils.formatPercent2 },
  netYieldAssetFee: { fieldId: "netYieldAssetFee", label: "Net Yield with Asset Man. Fee", formatter: JsUtils.formatPercent2 },
  recommendedConcession: { fieldId: "recommendedConcession", label: "Recommended Concession", formatter: JsUtils.formatCurrency },
  minimumAcceptableConcession: { fieldId: "minimumAcceptableConcession", label: "Min. Acceptable Concession", formatter: JsUtils.formatCurrency },
};

export enum Columns {
  transactions = "transactions",
  locations = "locations",
  characteristics = "characteristics",
  listingDetails = "listingDetails",
  assumptions = "assumptions",
  valuations = "valuations",
  neighborhood = "neighborhood",
  other = "other",
  closingAttorney = "closingAttorney",
}

export interface ColumnsSettings {
  id: string;
  expanded: Columns[];
}

export const defaultColumnsSettings: ColumnsSettings = {
  id: "default",
  expanded: [Columns.transactions, Columns.characteristics, Columns.listingDetails, Columns.closingAttorney],
};

export abstract class ListingDetailsColumnDefinition {

  private static fieldGroups = {
    print: [
      {
        show: true,
        expanded: true,
        defaultExpanded: true,
        title: 'Property Characteristics',
        detailsDef: [
          ListingColumns.BuyBoxV2,
          ListingColumns.PropertyType,
          ListingColumns.PropertySubtype,
          ListingColumns.Beds,
          ListingColumns.FullBaths,
          ListingColumns.HalfBaths,
          ListingColumns.BathsCount,
          ListingColumns.YearBuilt,
          ListingColumns.LivingAreaSqFt,
          ListingColumns.LotSizeAcres,

        ]
      },
      {
        show: true,
        expanded: true,
        defaultExpanded: true,
        title: 'Condition Report And Concessions',
        detailsDef: [
          PDFDetailsColumns.originalRehabBudget,
          PDFDetailsColumns.rehabBudget,
          PDFDetailsColumns.capRate,
          PDFDetailsColumns.netYield,
          PDFDetailsColumns.recommendedConcession,
          PDFDetailsColumns.minimumAcceptableConcession,
        ]
      }
    ]
  };

  static initialize(p: ListingProperty, columnsSettings: ColumnsSettings): DetailsGroupDef[]  {
    if (!columnsSettings) {
      return [];
    }

    let groups: DetailsGroupDef[] = [];
    if (ListingDetailsColumnDefinition.fieldGroups[columnsSettings.id]) {
      groups = ListingDetailsColumnDefinition.fieldGroups[columnsSettings.id];

    } else if (!ListingDetailsColumnDefinition.fieldGroups[columnsSettings.id]) {
      groups = [];
      ListingDetailsColumnDefinition.fieldGroups[columnsSettings.id] = groups;

      const transactions: DetailsGroupDef = {
        show: true,
        expanded: columnsSettings.expanded.includes(Columns.transactions),
        defaultExpanded: columnsSettings.expanded.includes(Columns.transactions),
        title: 'Transactions Details',
        detailsDef: [
          ListingColumns.TMStatus,
          ListingColumns.SubStatus,
          ListingColumns.InspectionStatus
        ]
      };
      groups.push(transactions);

      const locations: DetailsGroupDef = {
        show: true,
        expanded: columnsSettings.expanded.includes(Columns.locations),
        defaultExpanded: columnsSettings.expanded.includes(Columns.locations),
        title: 'Location',
        detailsDef: [
          ListingColumns.Address,
          ListingColumns.Unit,
          ListingColumns.City,
          ListingColumns.State,
          ListingColumns.ZipCode,
          ListingColumns.Region,
          ListingColumns.FIPS,
          ListingColumns.CountyName,
          ListingColumns.CensusTract,
          ListingColumns.Latitude,
          ListingColumns.Longitude,
          ListingColumns.AOPropertyID,
        ]
      };
      groups.push(locations);

      const characteristics: DetailsGroupDef = {
        show: true,
        expanded: columnsSettings.expanded.includes(Columns.characteristics),
        defaultExpanded: columnsSettings.expanded.includes(Columns.characteristics),
        title: 'Property Characteristics',
        detailsDef: [
          ListingColumns.PropertyType,
          ListingColumns.PropertySubtype,
          ListingColumns.Beds,
          ListingColumns.FullBaths,
          ListingColumns.HalfBaths,
          ListingColumns.BathsCount,
          ListingColumns.YearBuilt,
          ListingColumns.LivingAreaSqFt,
          ListingColumns.LotSizeAcres,

        ]
      };
      groups.push(characteristics);


      const listingDetails: DetailsGroupDef = {
        show: true,
        expanded: columnsSettings.expanded.includes(Columns.listingDetails),
        defaultExpanded: columnsSettings.expanded.includes(Columns.listingDetails),
        title: 'Listing Details',
        detailsDef: [
          ListingColumns.ListingStatus,
          ListingColumns.StatusDate,
          ListingColumns.CurrentPrice,
          ListingColumns.OrigPrice,
          ListingColumns.ListingDate,
          ListingColumns.RealDaysOnMarket,
          ListingColumns.NumberPrice,
          ListingColumns.TotalChangePrice,
          ListingColumns.ListingAgentName,
          ListingColumns.ListingBrokerName,
          ListingColumns.ListingAgentPhone,
          ListingColumns.ListingAgentEmail,
          ListingColumns.PublicRemarks,
          ListingColumns.PrivateRemarks,
          ListingColumns.MLSName,
          ListingColumns.MLSListingID,
          ListingColumns.AOListingID,
          ListingColumns.LastUpdate
        ],
      };
      groups.push(listingDetails);


      const assumptions: DetailsGroupDef = {
        show: true,
        expanded: columnsSettings.expanded.includes(Columns.assumptions),
        defaultExpanded: columnsSettings.expanded.includes(Columns.assumptions),
        title: 'Financial Assumptions',
        roles: [UserRole.Admin, UserRole.Uw],
        detailsDef: [
          ListingColumns.EstimatedRent,
          ListingColumns.EstimatedRentStdDev,
          ListingColumns.HOADues,
          ListingColumns.PropertyTaxAppraisal,
          ListingColumns.CurrentPropertyTax,
          ListingColumns.PropertyTaxYear,
          ListingColumns.SFHA,
          ListingColumns.ConditionScore,
          ListingColumns.OccupancyRate,
          ListingColumns.CreditLoss,
          ListingColumns.Concessions,
          ListingColumns.OtherIncome,
          ListingColumns.ManagementFee,
          ListingColumns.Maintenance1,
          ListingColumns.Maintenance2,
          ListingColumns.Maintenance3,
          ListingColumns.Maintenance4,
          ListingColumns.Maintenance5,
          ListingColumns.Maintenance6,
          ListingColumns.LeasingCost,
          ListingColumns.LeaseFee,
          ListingColumns.PropertyTaxRate,
          ListingColumns.MaxPropertyTaxValue,
          ListingColumns.InsuranceRate,
          ListingColumns.BidPrice,
          ListingColumns.BidPctofAsk,
          ListingColumns.ClosingCost,
          ListingColumns.AcquisitionCost,
          ListingColumns.TargetConditionScore,
          ListingColumns.MinRehabCost,
          ListingColumns.RentCondition1,
          ListingColumns.RehabCost12,
          ListingColumns.RentCondition2,
          ListingColumns.RehabCost23,
          ListingColumns.RentCondition3,
          ListingColumns.RehabCost34,
          ListingColumns.RentCondition4,
          ListingColumns.RehabCost45,
          ListingColumns.RentCondition5,
          ListingColumns.RehabCost56,
          ListingColumns.RentCondition6,
          ListingColumns.InitialCarryPeriod,
          ListingColumns.StabilizationFee,
          ListingColumns.RecurringCapEx,
          ListingColumns.Utilities,
          ListingColumns.StartupFee,

        ]
      };
      groups.push(assumptions);


      const valuations: DetailsGroupDef = {
        show: true,
        expanded: columnsSettings.expanded.includes(Columns.valuations),
        defaultExpanded: columnsSettings.expanded.includes(Columns.valuations),
        title: 'Valuation',
        detailsDef: [
          ListingColumns.SaleAVM,
          ListingColumns.SaleAVMStdDev,
          ListingColumns.LastSaleDate,
          ListingColumns.LastSalePrice,
          ListingColumns.LastRentDate,
          ListingColumns.LastRent,
          ListingColumns.Zestimate,
        ]
      };
      groups.push(valuations);

      const neighborhood: DetailsGroupDef = {
        show: true,
        expanded: columnsSettings.expanded.includes(Columns.neighborhood),
        defaultExpanded: columnsSettings.expanded.includes(Columns.neighborhood),
        title: 'Neighborhood',
        detailsDef: [
          ListingColumns.MedianIncome,
          ListingColumns.SchoolScoreElementary,
          ListingColumns.SchoolScoreMiddle,
          ListingColumns.SchoolScoreHigh,
          ListingColumns.CrimeScore,
        ]
      };
      groups.push(neighborhood);

      const other: DetailsGroupDef = {
        show: true,
        expanded: columnsSettings.expanded.includes(Columns.other),
        defaultExpanded:  columnsSettings.expanded.includes(Columns.other),
        title: 'Other',
        detailsDef: [
          ListingColumns.Occupant,
          ListingColumns.OwnerName,
          ListingColumns.Section8,
          ListingColumns.AiAlm
        ]
      };

      other.detailsDef.push(ListingColumns.ListingURL);

      groups.push(other);
    }

    if (p) {
      assignPropertyValues(p, ListingColumns);
      assignPropertyValues(p, PDFDetailsColumns);
    }

    return _.cloneDeep(groups);
  }
}

function assignPropertyValues(p, object) {
  const keys = Object.keys(object);
  _.each(keys, (k) => {
    const l = object[k];

    l.value = p[l.fieldId];

    if (l.formatter) {
      l.display = l.formatter(p[l.fieldId]);

    } else if (l.fieldId && l.fieldId2) {
      const diff = p[l.fieldId]- p[l.fieldId2];
      l.display = Math.abs(diff);
      l.pourcentage = Math.round((diff/p[l.fieldId2])*10000)/100;
      l.diff = diff > 0 ? true : false;
    } else {
      l.display = p[l.fieldId];
    }
  });
}
