import { Injectable } from '@angular/core';
import { ProFormaModel } from '../models/proformaModel';
import { ListingProperty } from './data/listingPropety';

import { calculate } from '@aveone/a1-proforma';

@Injectable({
  providedIn: 'root'
})
export class ProFormaCalculator {
  constructor() {
  }

  calculate(p: ListingProperty, pf: ProFormaModel, isAvm: boolean, isConditionGrid: boolean) {
    calculate(p, pf, isAvm, isConditionGrid);
  }
}

