import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute} from '@angular/router';

import * as _ from 'lodash';
import {Subscription} from "rxjs";

import {GridFilterService} from "src/app/ui/gridFilter/gridFilter.service";
import {ListingProperty} from 'src/app/services/data/listingPropety';
import {Preferences} from 'src/app/models/preferences';
import {PropertyStatusType} from 'src/app/services/data/propertyStatusType';
import {PropertyGridComponent} from './propertyGrid/propertyGrid.component';
import {PropertyListingColumnDefinition} from './propertyGrid/columnDefinition';
import {SortDropdownComponent} from 'src/app/ui/sortDropdown/sortDropdown.component';
import {RangePickerConfig} from "src/app/ui/range-datepicker/range-picker-config.interface";
import {SelectComponent} from "src/app/ui/select/select.component";
import {TIME_RANGE_CONFIGS, TimeRangeConfig, TimeRangeTypes} from "src/app/ui/range-datepicker/time-range-configs";
import {DownloadService, UnderwriterService} from "src/app/services";
import {DUPLICATE_SOURCE, IOnHold} from "src/app/services/data/proforma";
import {ModalConfig} from "src/app/ui/modal/modalConfig";
import {ModalService} from "src/app/ui/modal/modal.service";
import {ColumnState} from "ag-grid-community";
import {IViewSetting, IViewSettingOptions, ViewMode} from "src/app/ui/view-setting/viewSettingModel";
import {UNDERWRITER_ACTIONS} from "src/app/services/update-request-service/model/updateRequest";
import {
  UNDERWRITER_COMMANDS_NAME,
  UNDERWRITER_OPTIONS_LABEL
} from "src/app/services/update-request-service/model/underwriterCommandsAndActions";
import {UpdateRequestService} from "src/app/services/update-request-service/update-request.service";
import {StatusBarPanelComponent} from "src/app/panels/statusBar/statusBar.component";
import {downloadFile} from "src/app/utils/jsUtils";
import {FeatureFlagsService} from "src/app/services/featureFlagsService/feature-flags.service";
import {ActiveToast, ToastrService} from 'ngx-toastr';
import {RangeDatepickerComponent} from "../../ui/range-datepicker/range-datepicker.component";

@Component({
  selector: 'app-property-list',
  styleUrls: ['../styles/gridList.component.scss', '../styles/bottomStatusPanel.scss'],
  templateUrl: 'propertylist.component.html'
})
export class PropertyListComponent implements OnDestroy, OnInit {
  @ViewChild('propertyGrid') propertyGrid: PropertyGridComponent;
  @ViewChild('dateRangePicker') dateRangePicker: RangeDatepickerComponent;
  @ViewChild('sortChip') sortChip: SortDropdownComponent;
  @ViewChild('exportSelection') exportSelection: SelectComponent;
  @ViewChild('statusBar', {static: true}) statusBar: StatusBarPanelComponent;

  private subscriptions: Subscription[] = [];

  viewModes = ViewMode;
  viewMode: ViewMode = ViewMode.MAESTRO;

  isBottomStatusPanelOpened = false;
  isUnderwriterPanelOpened = false;

  preferences: Preferences;

  showHidden = false;
  rangePickerConfig: RangePickerConfig = null;
  filterModel: {} = {};
  sortModel: any[] = [];

  selectedRows = [];
  selectedAOListingIds = [];

  // For Column editor
  columnDef;
  columnState;
  isColumnEditorPanelOpened = false;

  listing: ListingProperty[];

  /**
   * If we arrive here from a shared link
   */
  aoListingId;

  gridName = null;

  isBusy = false;
  isDownloading = false;
  isAddressSearchActive = false;
  disableExportPDF = false;

  view = 'actionable';
  listingName = 'propertyListing';

  currentRangePickerConfig: RangePickerConfig;

  forceUpdateOnConflict = true;

  multiplePopup = false;

  viewSettingOptions: IViewSettingOptions[] = [
    {
      id: 'hiddenListing',
      label: 'Hidden properties',
      disabled: false,
      hidden: false,
      checked: false
    },
    {
      id: 'updatedListing',
      label: 'Listing updated only',
      disabled: false,
      hidden: false,
      checked: false
    }
  ];
  isGridLoading = false;
  isFetchCount = false;

  selectOptions = [
    {
      id: UNDERWRITER_ACTIONS.DRILLDOWN,
      label: UNDERWRITER_OPTIONS_LABEL.DRILLDOWN,
      disabled: false,
      main: true,
      actionID: UNDERWRITER_COMMANDS_NAME.drilldown
    }
  ];

  optionalActionDisabled = false;

  // export filtred properties as feature flag
  flag2818;

  uploadDisabled = true;
  private bulkPortfolioNames: any;

  private errorToast: ActiveToast<any>;

  private toastrOptionsError = {
    disableTimeOut: true,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private underwriterService: UnderwriterService,
    private gridFilterService: GridFilterService,
    private downloadService: DownloadService,
    private modalService: ModalService,
    private updateRequestService: UpdateRequestService,
    private featureFlagsService: FeatureFlagsService,
    private toastr: ToastrService
  ) {
    // get from session storage, for now just init to last 24h
    const timeRangeConfig: TimeRangeConfig = TIME_RANGE_CONFIGS.find(x => x.id === TimeRangeTypes.Last24Hours);
    if (timeRangeConfig) {
      this.currentRangePickerConfig = {
        timeRange: timeRangeConfig.id,
        startDate: timeRangeConfig.range[0],
        endDate: timeRangeConfig.range[1]
      };
    }

    this.downloadService.isDownloading.subscribe((isDownloading) => {
      this.isDownloading = isDownloading;
    });
    this.downloadService.isSearchAddressActive.subscribe((isAddressActive) => {
      this.isAddressSearchActive = isAddressActive;
    });
  }

  async ngOnInit() {
    this.underwriterService.getActiveTags();
    this.aoListingId = this.activatedRoute.snapshot.paramMap.get('aoListingId');
    if (this.aoListingId) {
      this.selectedAOListingIds = [this.aoListingId];
      this.isUnderwriterPanelOpened = true;
    }

    this.subscriptions.push(this.activatedRoute.data.subscribe(data => {
      this.viewMode = data['viewMode'];
    }));

    this.flag2818 = await this.featureFlagsService.getFeatureFlag('aom-2818');

    this.bulkPortfolioNames = await this.underwriterService.getBulkPortfolioNames();
    this.uploadDisabled = this.bulkPortfolioNames.length === 0;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = null;
  }

  onGridReady($event) {
    this.underwriterService.getPreferences().then((preferences: Preferences) => {
      this.preferences = preferences;

      // Only initialize name after preferences are loaded
      this.gridName = 'propertyGrid';

      if (this.viewMode === ViewMode.BULK) {
        this.gridName = 'bulkPropertyGrid';
        this.gridFilterService.init(this.gridName, PropertyListingColumnDefinition.bulkColumns, this.propertyGrid, preferences.bulkPropertyListing);
      } else {
        this.gridFilterService.init(this.gridName, PropertyListingColumnDefinition.columns, this.propertyGrid, preferences.propertyListing);
      }

      this.initViewSetting();
      this.rangePickerConfig = this.gridFilterService.getRangePickerConfig(this.gridName);
      this.filterModel = this.gridFilterService.getFilterModel(this.gridName);
      this.sortModel = this.gridFilterService.getSortModel(this.gridName);

      this.currentRangePickerConfig = this.rangePickerConfig;
    });
  }

  initViewSetting() {
    const viewSettingFlags = this.gridFilterService.getViewSetting(this.gridName);
    if (!_.isNil(viewSettingFlags)) {
      const flagsKey = Object.keys(viewSettingFlags);
      this.viewSettingOptions.forEach((vopt, index) => {
        if (flagsKey.includes(vopt.id)) {
          this.viewSettingOptions[index].checked = !!viewSettingFlags[vopt.id];
        }
      });
      this.showHidden = viewSettingFlags['hiddenListing'];
    } else {
      this.showHidden = false;
      this.gridFilterService.updateViewSetting(this.gridName, this.viewSettingOptions);
    }
    this.viewSettingOptions = [...this.viewSettingOptions];
  }

  openBottomStatusPanel() {
    this.isBottomStatusPanelOpened = true;
  }

  closeBottomStatusPanel() {
    this.isBottomStatusPanelOpened = false;
  }

  onBuyBoxChange(buyBoxSelectedId) {
    this.preferences.buyboxes.currentBuyBoxId = buyBoxSelectedId;

    this.propertyGrid.resetListingProperties();

    this.underwriterService.savePreferences(this.preferences);
  }

  onRowSelectionChanged(rows: any[]) {
    this.selectOptions = this.updateRequestService.getListingActions(rows);
    this.optionalActionDisabled = !(this.selectOptions || []).filter(so => {
      return !so.main;
    })
      .some(so => {
        return so.disabled == false;
      });

    const filtredRows = [];
    const filtredRowIds = [];
    rows.forEach(rw => {
      if (!filtredRowIds.includes(rw.AOListingID)) {
        filtredRowIds.push(rw.AOListingID);
        filtredRows.push(rw);
      }
    });

    this.disableExportPDF = false;
    this.disableExportPDF = _.some(filtredRows, (row) => ([
      PropertyStatusType.Underwritten,
      PropertyStatusType.New,
      PropertyStatusType.Updated,
      PropertyStatusType.UpdatedReason,
      PropertyStatusType.Hidden,
    ].includes(row.status)));

    this.selectedRows = filtredRows;
    this.isBottomStatusPanelOpened = filtredRows.length > 0;

  }

  onGridFilterChanged(filterModel) {
    this.gridFilterService.updateFilterModel(this.gridName, filterModel);
  }

  updateLoadingState(state) {
    // TODO: refresh from property grid
    this.isGridLoading = state;
  }


  async show() {
    await this.triggerShowHiddenState();
  }

  async hide() {
    await this.triggerShowHiddenState();
  }


  updateFetchCountState(state) {
    this.isFetchCount = state;
  }

  async triggerShowHiddenState() {
    this.isBusy = true;
    try {
      const hideResult = await this.underwriterService.hidePropertyListings(this.selectedRows, null, this.forceUpdateOnConflict);
      this.isBusy = false;
      if (hideResult.onHold) {
        if (this.forceUpdateOnConflict) {
          const conflictAoListingId = hideResult.onHold.map((onHoldItem: IOnHold) => {
            return onHoldItem.aoListingID;
          });
          const unConflictPropertiesIds = this.selectedRows.filter(selectedRow => {
            return !conflictAoListingId.includes(selectedRow.AOListingID);
          }).map(row => {
            return row.AOListingID;
          });
          if (unConflictPropertiesIds && unConflictPropertiesIds.length > 0) {
            this.propertyGrid.removeSelectedRows(unConflictPropertiesIds);
          }

        }
        const userAction = await this.handleDuplicateSubmission(hideResult.onHold as IOnHold[], this.multiplePopup);
        if (userAction) {
          this.closeBottomStatusPanel();
          this.propertyGrid.removeSelectedRows();
          this.unselectAll();
          this.propertyGrid.resetListingProperties();
        } else {
          return;
        }
        return;
      }

      this.closeBottomStatusPanel();
      this.propertyGrid.removeSelectedRows();
      this.unselectAll();
    } catch (error) {
      this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
      console.log(error);
      this.errorToast.onTap.subscribe(() => {
        this.errorToast = null;
      });
      this.isBusy = false;
    }
  }

  async ready() {
    this.isBusy = true;

    try {
      const offerResult = await this.underwriterService.savePropertyStatus(this.selectedRows, this.forceUpdateOnConflict);
      this.isBusy = false;
      if (offerResult.onHold) {
        if (this.forceUpdateOnConflict) {
          const conflictAoListingId = offerResult.onHold.map((onHoldItem: IOnHold) => {
            return onHoldItem.aoListingID;
          });
          const unConflictPropertiesIds = this.selectedRows.filter(selectedRow => {
            return !conflictAoListingId.includes(selectedRow.AOListingID);
          }).map(row => {
            return row.AOListingID;
          });
          if (unConflictPropertiesIds && unConflictPropertiesIds.length > 0) {
            this.propertyGrid.removeSelectedRows(unConflictPropertiesIds);
          }

        }
        const userAction = await this.handleDuplicateSubmission(offerResult.onHold as IOnHold[], this.multiplePopup);
        if (userAction) {
          this.closeBottomStatusPanel();
          this.propertyGrid.removeSelectedRows();
          this.unselectAll();
          this.propertyGrid.resetListingProperties();
        } else {
          return;
        }
        return;
      }

      this.closeBottomStatusPanel();
      this.propertyGrid.removeSelectedRows();
      this.unselectAll();
    } catch (error) {
      this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
      console.log(error);
      this.errorToast.onTap.subscribe(() => {
        this.errorToast = null;
      });
      this.isBusy = false;
    }
  }

  drilldown() {
    this.closeBottomStatusPanel();
    this.selectedAOListingIds = _.map(this.selectedRows, (r) => r.AOListingID);
    this.isUnderwriterPanelOpened = true;
  }

  executeListingAction($event) {
    this[$event]();
  }

  closeUnderwriterPanel(result) {
    this.isUnderwriterPanelOpened = false;

    if (result) {
      this.unselectAll();
    }

    this.aoListingId = null;
  }

  unselectAll() {
    this.propertyGrid.unselectAll();
    this.selectedRows = [];
    this.selectedAOListingIds = [];
  }

  // Columns
  openColumnEditorPanel() {
    this.columnState = this.propertyGrid.gridOptions.columnApi.getColumnState();
    this.columnDef = this.viewMode === ViewMode.MAESTRO ? PropertyListingColumnDefinition.columns : PropertyListingColumnDefinition.bulkColumns;
    this.isColumnEditorPanelOpened = true;
  }

  onColumnEditorPanelClosed() {
    this.columnState = null;
    this.columnDef = null;
    this.isColumnEditorPanelOpened = false;
  }

  saveColumns($event: any) {

    this.propertyGrid.gridOptions.columnApi.applyColumnState({
      state: $event.states,
      applyOrder: true,
    });
    this.gridFilterService.updateColumnState(this.gridName, $event.states, false);
    if (!$event.refreshSessionGridOnly) {
      this.propertyGrid.gridOptions.api.refreshServerSideStore({purge: true});
    }
  }

  onGridSortChanged(sortModel: any[]) {
    this.gridFilterService.updateSortModel(this.gridName, sortModel);
  }

  onGridContentUpdated($event: any) {
    if (!_.isNil($event.totalCount)) {
      this.gridFilterService.updateTotalCount(this.gridName, $event.totalCount);
    } else if (!_.isNil($event.startRow) && !_.isNil($event.endRow)) {
      this.gridFilterService.updateLoadedListingsCount(this.gridName, $event.startRow, $event.endRow);
    } else if ($event.resetFilteredCount) {
      this.gridFilterService.resetFilteredCount(this.gridName, $event.error);
    }
  }

  onDateRangeChange(e: RangePickerConfig) {
    this.currentRangePickerConfig = e;
    this.gridFilterService.updateRangePickerConfig(this.gridName, e);
    this.gridFilterService.resetFilteredCount(this.gridName);
  }

  onGridRangePickerChange($event: TimeRangeTypes) {
    this.dateRangePicker?.overrideRange($event);
  }

  async onViewSettingChanges(id) {
    this.viewSettingOptions = [...this.viewSettingOptions];
    const viewSettingParams: IViewSetting = {};
    this.viewSettingOptions.forEach(setting => {
      viewSettingParams[setting.id] = setting.checked ? 1 : 0;
    });
    this.showHidden = viewSettingParams['hiddenListing'];
    this.gridFilterService.resetFilteredCount(this.gridName);
    this.gridFilterService.updateViewSetting(this.gridName, this.viewSettingOptions);
  }

  async downloadFilteredProperties($event) {
    const params = this.propertyGrid.getFilteredPropertiesParams();
    params.buyBoxId = this.preferences.buyboxes.currentBuyBoxId;
    params.limit = this.flag2818?.downLoadLimit ? this.flag2818?.downLoadLimit : params.limit;
    params.sort = this.flag2818?.sort ? params.sort.gridSorting : params.sort.defaultSorting;
    params.fromLine = 0;
    this.downloadService.downloadFilteredProperties(params).then((response) => {
      downloadFile(response.stream as ArrayBuffer, response.contentType, response.excelFilename, response.blobLength);
    }, err => {
      alert(err);
    });
  }

  onPropertyUpdated(properties?: ListingProperty[]) {
    if (properties) {
      this.propertyGrid.updateProperties(properties);
      this.selectOptions = this.updateRequestService.getListingActions(properties);
      this.optionalActionDisabled = !(this.selectOptions || []).filter(so => {
        return !so.main;
      })
        .some(so => {
          return so.disabled == false;
        });
      return;
    }
    this.propertyGrid.resetListingProperties();
  }

  private async handleDuplicateSubmission(onHold: IOnHold[], multiplePopup: boolean) {
    let cLoop = true;
    return new Promise(async (resolve, reject) => {
      try {
        if (multiplePopup) {
          for (const onHoldItem of onHold) {
            if (cLoop) {
              const lastUpdateIdentity = onHoldItem?.user;
              onHoldItem.user = `${lastUpdateIdentity ?
                (lastUpdateIdentity.firstName || lastUpdateIdentity.lastName) ? lastUpdateIdentity.firstName + (lastUpdateIdentity.lastName ? ' ' + lastUpdateIdentity.lastName : '') : undefined
                : undefined}`;
              const holdItemAddress = this.selectedRows.filter(rowItem => {
                return rowItem.AOListingID == onHoldItem.aoListingID;
              });
              onHoldItem.address = holdItemAddress[0] ? holdItemAddress[0].Address : undefined;
              const userDecision = await this.triggerDuplicateSubmission([onHoldItem], multiplePopup, 'listing');
              if (!userDecision) {
                cLoop = false;
                resolve(false);
              }
            }
          }
          resolve(true);
        } else {
          const globalOnHold: IOnHold[] = onHold.map((onHoldItem) => {
            const lastUpdateIdentity = onHoldItem?.user;
            onHoldItem.user = `${lastUpdateIdentity ?
              (lastUpdateIdentity.firstName || lastUpdateIdentity.lastName) ? lastUpdateIdentity.firstName + (lastUpdateIdentity.lastName ? ' ' + lastUpdateIdentity.lastName : '') : undefined
              : undefined}`;
            const holdItemAddress = this.selectedRows.filter(rowItem => {
              return rowItem.AOListingID == onHoldItem.aoListingID;
            });
            onHoldItem.address = holdItemAddress[0] ? holdItemAddress[0].Address : undefined;
            return onHoldItem;

          });

          const userDecision = await this.triggerDuplicateSubmission(globalOnHold, multiplePopup, 'listing');
          if (!userDecision) {
            resolve(false);
          } else {
            resolve(true);
          }
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  async triggerDuplicateSubmission(onHold: IOnHold[], multiplePopup: boolean, origin?: DUPLICATE_SOURCE) {
    const config = new ModalConfig();
    config.type = 'yesno';
    config.title = 'Property cannot be saved';
    config.text = 'You cannot modify ';
    config.okText = multiplePopup ? 'Continue' : 'Reload listing';
    config.cancelText = 'Go back';
    config.width = '596px';
    config.options = {onHold, origin};

    const result = await this.modalService.open(config, 'DuplicateSubmissionComponent').catch(err => {
      return false;
    });

    if (result === 'cancel') {
      return false;
    }
    return true;
  }

  async triggerUploadModal() {
    return new Promise((resolve, reject) => {
      const config = new ModalConfig();
      config.type = 'ok';
      config.title = 'Upload Bulk Model';
      config.text = '';
      config.okText = 'Upload';
      config.width = '711px';
      config.options = {
        bulkPortfolioNames: this.bulkPortfolioNames,
      };

      this.modalService.open(config, 'UploadModal')
        .then((result) => {

          if (result.action === 'cancel') {
            return resolve({approval: 'cancel'});
          }

          return resolve({approval: true});
        })
        .catch((error) => {
          reject(error);
        });
    });

  }

  clearFilters($event) {
    // the next line does dateRangeOverride.emit(TimeRangeTypes.Last24Hours); and the close function refreshes the date range picker
    this.gridFilterService.clearFilters($event);
    this.dateRangePicker.close(true);
    this.propertyGrid.addressSearch = '';
  }
}
