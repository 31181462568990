import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from "@angular/core";
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { GridOptions, ColDef, RowNode, ColumnState } from 'ag-grid-community';
import 'ag-grid-enterprise';

import { UnderwriterService } from 'src/app/services';
import { ListingPreferences } from 'src/app/models/preferences';
import { HeaderComponent } from 'src/app/ui/agGrid/header-component/header.component';
import { IGridComponent } from 'src/app/ui/iGridComponent';
import { GridColumnTypes } from 'src/app/ui/agGrid/gridColumnTypes';
import { BaseListing } from "src/app/services/data/listing";
import * as JsUtils from 'src/app/utils/jsUtils';
import { UnderwriterStatusRendererComponent } from "../agGrid/underwriter-status-renderer/underwriter-status-renderer.component";
import { SelectCellGridComponent } from "../select-cell-grid/select-cell-grid.component";

@Component({
  selector: 'app-section-grid',
  templateUrl: 'sectionGrid.component.html',
  styleUrls: ['sectionGrid.component.scss'],
})
export class SectionGridComponent implements IGridComponent, OnInit, OnDestroy {
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();
  @Output() viewportChanged: EventEmitter<any> = new EventEmitter();
  @Output() sortChanged: EventEmitter<any> = new EventEmitter();
  @Output() batchSelectionChanged: EventEmitter<any[]> = new EventEmitter();
  @Output() gridReady: EventEmitter<void> = new EventEmitter();
  @Output() clearFilter: EventEmitter<string> = new EventEmitter();
  @Output() clearAddress: EventEmitter<void> = new EventEmitter();

  @Input() listingName: string;

  public _currentPreferences: ListingPreferences;
  @Input()
  get currentPreferences(): ListingPreferences {
    return this._currentPreferences;
  }
  set currentPreferences(currentPreferences: ListingPreferences) {
    this._currentPreferences = currentPreferences;
    if (currentPreferences) {
      this.updatePreferences();
    }
  }

  @Input()
  public columnDef: ColDef[];

  private _listingProperties: BaseListing[] = [];
  @Input()
  get listingProperties(): BaseListing[] {
    return this._listingProperties;
  }
  set listingProperties(properties: BaseListing[]) {
    this._listingProperties = properties;
    this.createRowData();
  }

  private _view: string;
  @Input()
  get view(): string {
    return this._view;
  }
  set view(view: string) {
    this._view = view;
    if (view) {
      this.updateView();
    }
  }

  gridOptions: GridOptions;
  gridName = 'genericGrid';

  private columnLayoutChanged: Subject<void> = new Subject<void>();
  private preferencesInitialized = false;

  private rowData: BaseListing[];
  private filterModel = null;
  private sortModel = null;

  private isReady = false;
  private lastActionIntervalHandle = null;

  constructor(
    public router: Router,
    private underwriterService: UnderwriterService) {

    // Debounce the column changes related events a bit so
    // we don't spam-save the preferences while resizing/moving a column
    this.columnLayoutChanged
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.savePreferences();
      });

    // we pass an empty gridOptions in, so we can grab the api out
    this.gridOptions = <GridOptions>{};
    this.gridOptions.columnTypes = GridColumnTypes.columnTypes;

    this.gridOptions.components = {
      /* custom cell renderer component */
      underwriterStatusRenderer: UnderwriterStatusRendererComponent,
      SelectCellGridComponent: SelectCellGridComponent
    };

    this.gridOptions.sideBar = false;
    this.gridOptions.multiSortKey = 'ctrl';
    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.rowHeight = 32,
    this.gridOptions.suppressCellFocus = true;

    this.gridOptions.defaultColDef = {
      headerComponent: <new () => HeaderComponent>HeaderComponent,
      headerComponentParams: {
        enableFilter: true,
        grid: this
      },
      menuTabs: ['filterMenuTab'],
      width: 150,
      minWidth: 40,
      enableRowGroup: false,
      enablePivot: false,
      resizable: true,
      sortable: true,
      filter: true,
      suppressColumnsToolPanel:true,
      suppressFiltersToolPanel: true,
      floatingFilter: false,
      filterParams:  {
        newRowsAction: 'keep'
      }
    };

    this.gridOptions.overlayLoadingTemplate =
      '<div class=" a1-grid-loader"><div class="a1-loader">Loading...</div></div>';

  }

  ngOnInit() {
    this.lastActionIntervalHandle = setInterval(() => this.updateOpUpdatedColumn(), 60000);
  }

  ngOnDestroy() {
    if (this.lastActionIntervalHandle) {
      clearTimeout(this.lastActionIntervalHandle);
      this.lastActionIntervalHandle = null;
    }
  }

  createRowData() {
    if (this.isReady) {
      if (!this.preferencesInitialized) {
        this.updatePreferences();
      }
      this.rowData = this._listingProperties;
      this.gridOptions.api.setRowData(this.rowData);
    }
  }

  updateRow(row: any) {
    const r = _.find(this.rowData, { AOListingID: row.AOListingID });
    if (r) {
      Object.assign(r, row);
      this.gridOptions.api.applyTransaction({ update: [r] });
    }
  }

  updateOpUpdatedColumn() {
    const updates = [];

    this.gridOptions.api.getRenderedNodes().forEach((node: RowNode) => {
      const tmp = JsUtils.getDateCalendarString(node.data.StatusUpdateDateMoment);
      if (tmp != node.data.StatusUpdateDateHuman) {
        node.data.StatusUpdateDateHuman = tmp;
        updates.push(node);
      }
    });

    // Don't use "gridOptions.api.applyTransaction()" to update the StatusUpdateDateHuman
    // as the actual data (StatusUpdateDateMoment) does not change and does not trigger a refresh of the cell
    if (updates.length) {
      this.gridOptions.api.refreshCells({
        rowNodes: updates,
        columns: ['StatusUpdateDateHuman'],
        force: true
      });
    }
  }

  getListingName() {
    return this.listingName;
  }

  unselectAll() {
    this.gridOptions.api.refreshCells();
    this.gridOptions.api.deselectAll();
  }

  removeSelectedRows() {
    const selected = this.gridOptions.api.getSelectedRows();
    this.gridOptions.api.applyTransaction({ remove: selected });
  }

  removeRow(row: any) {
    const r = _.find(this.rowData, { AOListingID: row.AOListingID });
    if (r) {
      this.gridOptions.api.applyTransaction({ remove: [r] });
    }
  }

  removeFilter(name: string) {
    // Get a reference to the 'name' filter instance
    const filterInstance = this.gridOptions.api.getFilterInstance(name);

    filterInstance.setModel(null);

    // Tell grid to run filter operation again
    this.gridOptions.api.onFilterChanged();

    this.filterModel = this.gridOptions.api.getFilterModel();
  }

  updateFilterModel(filterModel, sortModel) {
    let filterModelChanged = false;
    if(!_.isEqual(this.filterModel, filterModel)) {
      this.filterModel = filterModel;
      filterModelChanged = true;
    }

    let sortModelChanged = false;
    if(!_.isEqual(this.sortModel, sortModel)) {
      this.sortModel = sortModel;
      sortModelChanged = true;
    }

    if (!filterModelChanged && !sortModelChanged) {
      return;
    }

    if (filterModelChanged) {
      this.gridOptions.api.setFilterModel(this.filterModel);
    }

    if (sortModelChanged) {
      this.gridOptions.columnApi.applyColumnState({
        state: this.sortModel
      });
    }
  }

  initPreferences(filterModel, sortModel, columnsState) {
    let filterModelChanged = false;
    if(!_.isEqual(this.filterModel, filterModel) && !!filterModel) {
      this.filterModel = filterModel;
      filterModelChanged = true;
    }

    let sortModelChanged = false;
    if(!_.isEqual(this.sortModel, sortModel) && !!sortModel) {
      this.sortModel = sortModel;
      sortModelChanged = true;
    }

    let columnsStateChanged = false;
    if(columnsState) {
      columnsStateChanged = true;
    }

    if (!filterModelChanged && !sortModelChanged && !columnsStateChanged) {
      return;
    }

    if (filterModelChanged) {
      this.gridOptions.api.setFilterModel(this.filterModel);
    }

    if (sortModelChanged) {
      this.gridOptions.columnApi.applyColumnState({
        state: this.sortModel
      });
    }

    if (columnsStateChanged) {
      this.gridOptions.columnApi.applyColumnState({
        state: columnsState,
        applyOrder: true
      });
    }
  }

  getFilterModel() {
    return this.gridOptions.api.getFilterModel();
  }


  getSortModel() {
    const colState = this.gridOptions.columnApi.getColumnState();
    return colState
    .filter((s) => {
      return s.sort != null;
    })
    .map((s) => {
      return { colId: s.colId, sort: s.sort };
    });
  }

  updateSortModel(sortModel) {
    this.sortModel = sortModel;
    this.gridOptions.columnApi.applyColumnState({
      state: this.sortModel
    });
  }

  updateColumnsStates(columns: ColumnState[]) {
    this.gridOptions.columnApi.applyColumnState({
      state: columns,
      applyOrder: true
    });
  }

  updateView() {
    if (!this.isReady || !this._view) {
      return;
    }

    this.gridOptions.api.showLoadingOverlay();
    this.gridOptions.api.setColumnDefs(this.columnDef);
    this.updatePreferences();
    this.isReady = true;

    this.gridReady.emit();
  }

  private updatePreferences() {
    if (!this.isReady || !this.currentPreferences) {
      return;
    }

    if (this.currentPreferences && !JsUtils.isNullOrEmpty(this.currentPreferences.columns)) {
      this.gridOptions.columnApi.applyColumnState({
        state: this.currentPreferences.columns,
        applyOrder: true
      });
    }
    this.preferencesInitialized = true;
  }

  async savePreferences() {
    if (!this.preferencesInitialized) {
      return;
    }
    this.currentPreferences.columns = this.gridOptions.columnApi.getColumnState();
    await this.underwriterService.savePreferences();
  }

  showLoader() {
    this.gridOptions.api.showLoadingOverlay();
  }

  hideLoader() {
    if (this.gridOptions.api) {
      this.gridOptions.api.hideOverlay();
    }
  }

  onReady() {
    this.gridOptions.api.showLoadingOverlay();
    this.gridOptions.api.setColumnDefs(this.columnDef);
    this.updatePreferences();
    this.isReady = true;

    this.gridReady.emit();
  }

  onSelectionChanged($event) {
    const rows = this.gridOptions.api.getSelectedRows();
    this.batchSelectionChanged.emit(rows);
  }

  onFilterChanged($event) {
    this.filterModel = this.gridOptions.api.getFilterModel();
    this.filterChanged.emit(this.filterModel);
    this.updateOpUpdatedColumn();
  }

  onViewportChanged($event) {
    this.viewportChanged.emit();
  }

  onSortChanged($event) {
    this.sortModel = this.getSortModel();

    _.each(this.sortModel, (m) => {
      const col = _.find(this.columnDef, (c) => {
        return c.field == m.colId;
      });
      m.display = col.headerName;
    });

    this.sortChanged.emit(this.sortModel);
    this.updateOpUpdatedColumn();
  }

  // here we use one generic event to handle all the column type events.
  // the method just prints the event name
  onColumnEvent($event) {
    if (
      $event.source == 'api' || // 'api' are called when we programmatically set the columns width, etc,
      $event.type == "columnEverythingChanged" ||
      ($event.type == "columnResized" && !$event.finished) ||
      ($event.type == "columnResized" && !$event.finished)
    ) {
      return;
    }
    // Notify changes to save layout preferences
    this.columnLayoutChanged.next();
  }

  isColumnFilterAvailable(colId) {
    return true;
  }

  clearFilters() {
    this.clearFilter.emit(this.gridName);
  }

  clearAddressSearch() {
    this.clearAddress.emit();
  }
}
