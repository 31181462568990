import { Injectable, Input } from '@angular/core';
import {ColDef, ColumnState} from 'ag-grid-community';

import * as _ from 'lodash';
import * as moment from 'moment';
import * as numeral from 'numeral';

import * as JsUtils from 'src/app/utils/jsUtils';
import { ListingPreferences, UserFilter } from 'src/app/models/preferences';
import { IGridComponent } from '../iGridComponent';
import {GridFilterBaseState, GridFilterState} from './gridFilterState';
import { UnderwriterService } from 'src/app/services';
import { PropertyGridComponent } from 'src/app/pages/propertylist/propertyGrid/propertyGrid.component';
import { UnderwritingListPropertyStatusType } from 'src/app/services/data/propertyStatusType';
import {RangePickerConfig} from "../range-datepicker/range-picker-config.interface";
import {TimeRangeTypes} from "../range-datepicker/time-range-configs";
import { IViewSetting, IViewSettingOptions } from '../view-setting/viewSettingModel';

@Injectable()
export class GridFilterService {

  private states: {
    [key: string]: GridFilterState;
  } = {};

  constructor(private underwriterService: UnderwriterService) {
  }

  init(gridName, columns: ColDef[], grid: IGridComponent, listingPreferences: ListingPreferences, toggleFiltersOnDefault = true, view = null) {
    let sessionFilterStateJson = sessionStorage.getItem(gridName);
    let sessionFilterState = sessionFilterStateJson ? JSON.parse(sessionFilterStateJson) : null;

    let columnsState;

    this.states[gridName] = new GridFilterState();
    this.states[gridName].grid = grid;
    this.states[gridName].columns = columns;
    this.states[gridName].listingPreferences = listingPreferences;
    this.states[gridName].toggleFiltersOn = toggleFiltersOnDefault;

    if (sessionFilterState) {
      const filtersPreference = listingPreferences.filters.filters.filter(filter => filter.name == sessionFilterState.currentFilterId && filter.shareFilter == sessionFilterState.shareFilter);
      if(filtersPreference[0]
          && (!_.isEqual(sessionFilterState.filterModel, filtersPreference[0].filterModel)
                || !_.isEqual(sessionFilterState.sortModel, filtersPreference[0].sortModel))) {

        this.states[gridName].currentFilterId = filtersPreference[0].name;
        this.states[gridName].shareFilter = filtersPreference[0].shareFilter;
        this.updateFilterModel(gridName, filtersPreference[0].filterModel, null);
        this.updateSortModel(gridName, filtersPreference[0].sortModel);
        sessionFilterStateJson = sessionStorage.getItem(gridName);
        sessionFilterState = sessionFilterStateJson ? JSON.parse(sessionFilterStateJson) : null;
      }


      Object.assign(this.states[gridName], sessionFilterState);
      columnsState = this.getColumnsState(gridName);
      if(columnsState) {
        this.updateColumnState(gridName, columnsState, false);
      }
      this.states[gridName].grid.initPreferences(this.states[gridName].filterModel, this.states[gridName].sortModel, columnsState);
    } else {
      this.states[gridName].view = view;
      this.states[gridName].grid.initPreferences(null, null, columnsState);
    }
  }

  clear() {
    this.states = {};
  }

  getCurrentView(gridName) {
    return this.states[gridName]?.view;
  }

  getState(gridName) {
    return this.states[gridName];
  }

  updateFilterModel(gridName, filterModel, view = null) {
    const state = this.states[gridName];
    if (state) {
      state.isBusy = true;
      state.filterModel = filterModel;
      state.totalFiltredCount = 0;
      state.view = view;
      this.filterModelToChips(state, filterModel);
      this.updateShowChips(state);
      this.saveToSessionStorage(gridName, state);
      state.isBusy= false;
    }
  }

  updateView(gridName, view) {
    const state = this.states[gridName];
    if (state) {
      state.view = view;
      this.filterModelToChips(state, state.filterModel);
      this.updateShowChips(state);
      this.saveToSessionStorage(gridName, state);
    }
  }

  updateSortModel(gridName, sortModel) {
    const state = this.states[gridName];
    if (state) {
      state.sortModel = sortModel;
      this.updateShowChips(state);
      this.saveToSessionStorage(gridName, state);
    }
  }

  updatePreferences(gridName, listingPreferences: ListingPreferences) {
    const state = this.states[gridName];
    if (state) {
      state.listingPreferences = listingPreferences;

      this.filterModelToChips(state, state.filterModel);
      this.updateShowChips(state);
    }
  }

  updateColDefs(gridName, columns: ColDef[]) {
    const state = this.states[gridName];
    if (state) {
      state.columns = columns;

      this.filterModelToChips(state, state.filterModel);
      this.updateShowChips(state);
    }
  }

  unselectFilter(gridName) {
    const state = this.states[gridName];
    state.hasFiltersChanged = false;
    state.currentFilterId = null;
  }

  clearFilters(gridName) {
    const state = this.states[gridName];
    state.showChips = false;
    state.hasFiltersChanged = false;
    state.currentFilterId = null;
    state.shareFilter = false;
    state.grid.clearAddressSearch();
    state.grid.updateFilterModel(null, null);
  }

  filterSelectionChange(gridName, filterId, shareFilter) {
    const state = this.states[gridName];

    state.currentFilterId = filterId;
    state.isBusy = true;

    if (state.listingPreferences.filters) {
      const userFilter: UserFilter = state.listingPreferences?.filters?.filters.filter(el => el.shareFilter === shareFilter && el.name === filterId ).pop();

      if (userFilter) {
        state.showChips = true;
        state.sortModel = userFilter.sortModel;
        state.filterModel = userFilter.filterModel;
        state.shareFilter = userFilter.shareFilter;
        // this.filterModelToChips(state, userFilter.filterModel);

        const filterSortKeys = [
          ...Object.keys(state.filterModel),
          ...state.sortModel.map(x => x.colId)
        ];

        if(state.columnsStates && filterSortKeys.length > 0) {
          state.columnsStates.forEach(column => {
            if(filterSortKeys.includes(column.colId)) {
              column.hide = false;
            }
          });
        }
        state.grid.updateColumnsStates(state.columnsStates);
        state.grid.updateFilterModel(userFilter.filterModel, userFilter.sortModel);

      } else {
        state.showChips = false;
        state.sortModel = null;
        state.filterModel = null;
        state.shareFilter = false;
        // this.filterModelToChips(state, null);
        state.grid.updateFilterModel(null, null);
      }
    }
    state.totalFiltredCount = 0;
    state.isBusy = false;
  }

  async addNewFilter(gridName, newFilterName, shareFilter) {
    return new Promise((resolve, reject) => {
    const state = this.states[gridName];

    let f: UserFilter = _.find(state.listingPreferences?.filters?.filters, { name: newFilterName });

    if (!f) {
      f = new UserFilter();
      f.name = newFilterName;

      if (!!state.listingPreferences?.filters?.filters) {
        state.listingPreferences.filters.filters.push(f);
      } else {
        if (!state.listingPreferences?.filters || !state.listingPreferences.filters.filters || !Array.isArray(state.listingPreferences.filters.filters)) {
          state.listingPreferences.filters = { filters: [] };
        }

        state.listingPreferences.filters.filters = [f];
      }
    }

    f.filterModel = state.grid.getFilterModel();
    f.sortModel = state.grid.getSortModel();
    f.shareFilter = shareFilter;

    state.hasFiltersChanged = false;
    this.underwriterService.updateFilters(state.grid.getListingName(), state.listingPreferences?.filters)
    .then(() => {
      state.currentFilterId = newFilterName;
      state.shareFilter = shareFilter;
      this.saveToSessionStorage(gridName, state);
      resolve(null);
    }).catch(error => reject(error));
  });
  }

  deleteFilter(gridName, filterId, shareFilter) {
    const state = this.states[gridName];
    if (state.currentFilterId == filterId && state.shareFilter == shareFilter) {
      this.unselectFilter(gridName);
    }

    const idx = _.findIndex(state.listingPreferences?.filters?.filters, { name: filterId } );
    if (idx > -1) {
      state.listingPreferences.filters.filters.splice(idx, 1);
    }
    this.saveToSessionStorage(gridName, state);
    this.underwriterService.updateFilters(state.grid.getListingName(), state.listingPreferences?.filters);
  }

  removeFilterChip(gridName, filterKey, idx) {
    const state = this.states[gridName];

    state.grid.removeFilter(filterKey);
    state.filterChips.splice(idx, 1);
    this.saveToSessionStorage(gridName, state);
  }

  onSortDropdownModelChanged(gridName, sortModel) {
    const state = this.states[gridName];
    state.totalFiltredCount = 0;
    state.sortModel = sortModel;

    this.updateShowChips(state);
    state.grid.updateSortModel(sortModel);
    this.saveToSessionStorage(gridName, state);
  }

  updateShowChips(state: GridFilterState) {
    state.showChips = state.filterChips.length > 0  || state.sortModel && state.sortModel.length > 0;
  }

  updateColumnState(gridName, states: ColumnState[], usePreferenceState = true): void {
    let preferencesState;
    const gridState = this.getState(gridName);

    if (!gridState) {
      return;
    }

    if(gridState.currentFilterId && gridState?.listingPreferences?.filters?.filters) {
      preferencesState = gridState.listingPreferences.filters.filters.filter(filter => {
        return filter.name === gridState.currentFilterId && filter.shareFilter === gridState.shareFilter;
      });
    }

    preferencesState = preferencesState?.[0] ? preferencesState[0] : {
      sortModel: [],
      filterModel:{}
    };

    gridState.filterModel = (Object.keys(preferencesState.filterModel).length> 0 && usePreferenceState) ? {...gridState.filterModel, ...preferencesState.filterModel} : gridState.filterModel;
    gridState.sortModel = (preferencesState.sortModel.length> 0 && usePreferenceState) ? _.uniqBy([...gridState.sortModel, ...preferencesState.sortModel], function (e) {
      return e.colId;
    }) : gridState.sortModel;

    const sortModel =_.cloneDeep(gridState.sortModel);
    const filterModel =_.cloneDeep(gridState.filterModel);


    gridState.columnsStates = states;
    this.setActiveFilterId(gridState);
    gridState.grid.updateFilterModel(filterModel, sortModel);
    gridState.filterModel = filterModel;
    gridState.sortModel = sortModel;
    this.saveToSessionStorage(gridName, gridState);
  }

  private setActiveFilterId(gridState: GridFilterState) {
    let activeFilter;
    if(!gridState.currentFilterId || !gridState?.listingPreferences?.filters?.filters) {
      return;
    }

    activeFilter= gridState.listingPreferences.filters.filters.find(filter => {
      return _.isEqual(filter.sortModel, gridState.sortModel) && _.isEqual(filter.filterModel, gridState.filterModel);
    });

    if(activeFilter?.[0]) {
      gridState.currentFilterId = activeFilter[0].name;
      gridState.currentFilterId = activeFilter[0].shareFilter;
    }

  }


  updateFilterSortFromColumnState(gridName, states: ColumnState[]): void {
    const gridState = this.getState(gridName);

    if (!gridState) {
      return;
    }

    const filterSortKeys = [
      ...Object.keys(gridState.filterModel),
      ...gridState.sortModel.map(x => x.colId)
    ];

    if (filterSortKeys.length === 0) {
      return;
    }

    const hiddenColumnKeys = states
      .filter(state => state.hide)
      .map(x => x.colId);

    filterSortKeys.forEach(key => {
      if (hiddenColumnKeys.includes(key)) {

        // remove filter
        if (gridState.filterModel[key]) {
          delete gridState.filterModel[key];
        }

        // remove sort
        const sortIndex = gridState.sortModel.findIndex(x => x.colId === key);
        if (sortIndex >= 0) {
          gridState.sortModel.splice(sortIndex, 1);
        }

      }
    });

    gridState.grid.updateFilterModel(gridState.filterModel, gridState.sortModel);
  }

  updateShowHiddenFlag(gridName: string, flag: boolean) {
    const state = this.states[gridName];
    if (state) {
      state.showHidden = flag;
      this.saveToSessionStorage(gridName, state);
    }
  }

  updateViewSetting(gridName: string, setting: IViewSettingOptions[]) {
    const state = this.states[gridName];
    if (state) {
      const viewSettingParams = {};
      (setting || []).forEach(settingItem => {
        viewSettingParams[settingItem.id] = settingItem.checked? 1 : 0;
      });
      state.viewSetting = viewSettingParams;
      this.saveToSessionStorage(gridName, state);
    }
  }

  getShowHiddenFlag(gridName: string) {
    return this.states[gridName] ? this.states[gridName].showHidden : false;
  }

  getViewSetting(gridName: string): IViewSetting {
    return this.states[gridName] ? this.states[gridName]?.viewSetting : {};
  }

  updateShowTerminatedFlag(gridName: string, flag: boolean) {
    const state = this.states[gridName];
    if (state) {
      state.showTerminated = flag;
      this.saveToSessionStorage(gridName, state);
    }
  }

  getShowTerminatedFlag(gridName: string) {
    return this.states[gridName] ? this.states[gridName].showTerminated : false;
  }

  updateRangePickerConfig(gridName: string, rangePickerConfig: RangePickerConfig) {
    const state = this.states[gridName];
    if (state) {
      state.rangePickerConfig = {
        timeRange: rangePickerConfig.timeRange,
        startDate: rangePickerConfig.startDate?.format(),
        endDate: rangePickerConfig.endDate?.format()
      };
      this.saveToSessionStorage(gridName, state);
    }
  }

  getRangePickerConfig(gridName: string): RangePickerConfig {
    const state = this.states[gridName];
    if (!state || !state.rangePickerConfig) {
      return {
        endDate: moment().add(1, 'day'),
        startDate: moment().subtract(24, 'hours'),
        timeRange: TimeRangeTypes.Last24Hours
      };
    }

    const config = state.rangePickerConfig;
    return {
      endDate: config.endDate ? moment(config.endDate): null,
      startDate: config.startDate ? moment(config.startDate) : null,
      timeRange: config.timeRange,
    };
  }

  private filterModelToChips(state: GridFilterState, filterModel: any) {
    state.filterChips = [];
    if (!filterModel) {
      return;
    }

    const filterKeys = Object.keys(filterModel);
    _.each(filterKeys, (k) => {
      const filter = filterModel[k];
      if (
        k !== 'underwritingStatus' ||
        state.grid instanceof PropertyGridComponent && (filter.values.length !== UnderwritingListPropertyStatusType.length - 1)
      ) {
        const col = _.find(state.columns, { field: k }) || _.find(state.columns, { colId: k });

        let description = '';

        if (filter.filterType == 'set') {
          const tmp: any[] = filter.values;

          description = tmp.length? tmp.join(' OR ') : ' - ';

        } else {

          if (filter.operator) {
            description = `${this.getOperand(filter.condition1)} ${filter.operator} ${this.getOperand(filter.condition2)}`;
          } else {
            description = this.getOperand(filter);
          }
        }

        if(col) {
          state.filterChips.push({
            key: k,
            // @ts-ignore
            name: col.headerName || col.label || col.colId,
            description: description
          });
        }
      }
    });

    this.updateFilterHasChanged(state);
  }

  private updateFilterHasChanged(state: GridFilterState) {
    if (!state.listingPreferences) {
      state.hasFiltersChanged = false;
      return;
    }

    const userFilter: UserFilter = _.find(state.listingPreferences?.filters?.filters, { name: state.currentFilterId, shareFilter: state.shareFilter } );
    if (!userFilter && (state.filterChips.length > 0 || state.grid.getSortModel().length > 0 )) {
      state.hasFiltersChanged = true;

    } else if (userFilter && state.filterChips.length > 0) {
      // const strGridModel = JSON.stringify(state.grid.getFilterModel());
      // const strUserFilterModel = JSON.stringify(userFilter.filterModel);

      // console.log("this.grid.getFilterModel()", strGridModel);
      // console.log("(userFilter.filterModel)",strUserFilterModel);

      state.hasFiltersChanged = JSON.stringify(state.grid.getFilterModel()) != JSON.stringify(userFilter.filterModel);

      state.hasFiltersChanged = state.hasFiltersChanged || JSON.stringify(state.grid.getSortModel()) != JSON.stringify(userFilter.sortModel);
    } else if (userFilter) {
      state.hasFiltersChanged = JSON.stringify(state.grid.getSortModel()) != JSON.stringify(userFilter.sortModel);
    } else {
      state.hasFiltersChanged = false;
    }

    // console.log("hasFiltersChanged",this.hasFiltersChanged);
  }

  private getFilterValue(filter) {
    if (filter.filterType == 'number') {
      return filter.filter;
    } else if (filter.filterType == 'date') {
      return JsUtils.getDateString(filter.dateFrom);
    } else if (filter.filterType == 'text') {
      return filter.filter;
    }
  }

  private getFilterToValue(filter) {
    if (filter.filterType == 'number') {
      return filter.filterTo;
    } else if (filter.filterType == 'date') {
      return JsUtils.getDateString(filter.dateTo);
    } else if (filter.filterType == 'text') {
      return filter.filterTo;
    }
  }

  private getOperand(filter) {
    let description = '';

    switch (filter.type) {
      case 'greaterThan':
        description = '> ' + this.getFilterValue(filter);
        break;

      case 'greaterThanOrEqual':
        description = '>= ' + this.getFilterValue(filter);
        break;

      case 'lessThan':
        description = '< ' + this.getFilterValue(filter);
        break;

      case 'lessThanOrEqual':
        description = '<= ' + this.getFilterValue(filter);
        break;

      case 'notContains':
      case 'notEqual':
        description = '<> ' + this.getFilterValue(filter);
        break;

      case 'inRange':
        description = this.getFilterValue(filter) + '-' + this.getFilterToValue(filter);
        break;

      case 'blank':
        description = 'is blank';
        break;

      case 'notBlank':
        description = 'is not blank';
        break;


      default:
        description = this.getFilterValue(filter);
        break;
    }

    return description;
  }

  public getFilterModel(gridName: string) {
    return this.states[gridName]?.filterModel ?? null;
  }

  public getColumnsState(gridName: string) {
    return this.states[gridName]?.columnsStates ?? null;
  }

  public getSortModel(gridName: string) {
    return this.states[gridName]?.sortModel ?? null;
  }

  public getTotalCount(gridName: string): string {
    return !_.isNil(this.states[gridName]?.totalCount) ? numeral(this.states[gridName]?.totalCount).format('0[,]0') : 'Loading...';
  }

  public getLoadedRowCount(gridName: string): any {
    const totalCount = !_.isNil(this.states[gridName]?.totalCount) ? numeral(this.states[gridName]?.totalCount).format('0[,]0') : 'Loading...';
    const startRow = this.states[gridName]?.startRow ? numeral(this.states[gridName]?.startRow).format('0[,]0') : 0;
    const endRow = this.states[gridName]?.endRow ? numeral(this.states[gridName]?.endRow).format('0[,]0') : 0;
    return  {totalCount: totalCount, startRow: startRow, endRow: endRow};
  }


  public updateTotalCount(gridName: string, count: number) {
    const state = this.states[gridName];
    if (state) {
      state.totalCount = count;
      state.totalFiltredCount = count;
    }
  }

  public resetFilteredCount(gridName: string, error?) {
    const state = this.states[gridName];
    if (state) {
      state.totalFiltredCount = error? 1 : 0;
    }
  }

  public updateLoadedListingsCount(gridName: string, startRow: number, endRow: number) {
    const state = this.states[gridName];
    if (state) {
      state.startRow = startRow;
      state.endRow = endRow;
    }
  }

  private saveToSessionStorage(gridName: string, state: GridFilterState) {
    if (state) {
      const session = new GridFilterBaseState();
      session.currentFilterId = state.currentFilterId;
      session.shareFilter = state.shareFilter;
      session.filterModel = state.filterModel;
      session.showHidden = state.showHidden;
      session.viewSetting = state.viewSetting;
      session.showTerminated = state.showTerminated;
      session.sortModel = state.sortModel;
      session.timeId = state.timeId;
      session.view = state.view;
      session.rangePickerConfig = state.rangePickerConfig;
      session.columns = state.columns;
      session.columnsStates = state.columnsStates;
      sessionStorage.setItem(gridName, JSON.stringify(session));
    }
  }
}
