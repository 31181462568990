import {Location} from '@angular/common';
import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from '@angular/router';
import {animate, style, transition, trigger,} from '@angular/animations';

import * as _ from 'lodash';

import {ApiService, DownloadService, UnderwriterService } from 'src/app/services';
import {MissingData, ProFormaModel} from 'src/app/models/proformaModel';
import {IOnHold, ProForma} from 'src/app/services/data/proforma';
import {ListingProperty} from 'src/app/services/data/listingPropety';
import {InvestorApprovalType, PropertyStatusType} from 'src/app/services/data/propertyStatusType';
import {ModalComponent} from 'src/app/ui/modal/modal.component';
import {ModalService} from 'src/app/ui/modal/modal.service';
import {ModalConfig} from 'src/app/ui/modal/modalConfig';
import {ComparablesPanelComponent} from '../comparables/comparables.component';
import {UnderwriterViewModel, UNDERWRITE_PANEL} from './underwriterViewModel';
import {ProformaPanelComponent} from '../proforma/proforma.component';
import * as JsUtils from '../../utils/jsUtils';
import {downloadFile} from '../../utils/jsUtils';
import {ColumnsSettings, defaultColumnsSettings} from "../propertyDetails/columnDefinition";
import {PropertyDetailsSaveValues} from '../propertyDetails/propertyDetails.component';
import {IProformaRow, ITargetOption} from '../proforma/proforma';
import {PROFORMA_FIELDS_IDS, ProformaConfig, VALIDATION_ERROR_TYPE} from '../proforma/proforma_config';
import {ActivityLogService} from 'src/app/services/activityLogService/activity-log.service';
import {
  CONCESSION_ACTION,
  IUpdateRequest,
  STEP_ACTION,
  UNDERWRITER_ACTIONS,
  WORKFLOW_STATES
} from 'src/app/services/update-request-service/model/updateRequest';
import {UpdateRequestService} from 'src/app/services/update-request-service/update-request.service';
import {ActiveToast, ToastrService} from 'ngx-toastr';
import {APPROVAL_ACTIONS, IApproval, ITriggerEVBusEvent} from 'src/app/services/update-request-service/model/IRequest';
import {
  UNDERWRITER_COMMANDS_NAME,
  UNDERWRITER_OPTIONS_LABEL
} from 'src/app/services/update-request-service/model/underwriterCommandsAndActions';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AutoApprovalService} from 'src/app/services/autoApproval/auto-approval.service';
import {GridFilterService} from "../../ui/gridFilter/gridFilter.service";
import {ListingSources} from "../../services/data/ListingSources";
import {IUnderwriterNote, IRejectionComment} from 'src/app/models/inderwriterNoteModel';
import { ClassificationCategoryTypeFromStatus, CLASSIFICATIONS_LABELS, ClassificationToolTipSetup } from 'src/app/services/data/classification';
import { IProformaVersionModel, PROFORMA_VERSION_SOURCE } from 'src/app/services/activityLogService/proformaVersioningService';
import { ViewMode } from 'src/app/ui/view-setting/viewSettingModel';
import { FeatureFlagsService } from 'src/app/services/featureFlagsService/feature-flags.service';

type navigationDirection = 'asc'| 'desc';

@Component({
  selector: 'app-underwriter-panel',
  styleUrls: ['underwriter.component.scss'],
  templateUrl: 'underwriter.component.html',

  animations: [
    trigger('openClose', [
      transition(':leave', [
        animate('600ms', style({ height: 0 }))
      ]),
    ]),
    trigger('slideAddress', [
      transition(':enter', [
        style({height: '0'}),
        animate('150ms ease-in', style({height: '*'}))
      ]),
      transition(':leave', [
        animate('150ms ease-in', style({height: '0'}))
      ]),
    ]),
  ],
})
export class UnderwriterPanelComponent {
  @Output() onPropertyUpdated: EventEmitter<ListingProperty[]> = new EventEmitter();
  enableBuyBoxV2 = true;
  enableInterimBBC = false;
  viewModes = ViewMode;
  viewMode: ViewMode = ViewMode.MAESTRO;
  baseUrl: string = '/underwriting';

  private _propertyIds: any[] = [];
  proformaConfig: ProformaConfig = new ProformaConfig();
  @Input()
  get propertyIds(): any[] {
    return this._propertyIds;
  }
  set propertyIds(values: any[]) {
    this._propertyIds = values;
    this.initialize();
  }

  private _open = false;
  @Input()
  get open(): boolean {
    return this._open;
  }
  set open(values: boolean) {
    this._open = values;
    this.initialize();
  }

  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() finishedLoading: EventEmitter<any> = new EventEmitter();
  @Output() listingReady: EventEmitter<any> = new EventEmitter();

  @ViewChild('comparablesPanel', { static: true }) comparablesPanel: ComparablesPanelComponent;
  @ViewChild('proformaPanel', { static: true }) proformaPanel: ProformaPanelComponent;

  private proformaChangesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private proformaChangesObservable: Observable<any> = this.proformaChangesSubject.asObservable();
  private proformaChangesSubscriber: Subscription;

  isAddressVisible = true;
  addressTooltip = 'Click to copy';

  aoListingId;
  private proformaUpdated = false;
  saveStart = false;
  saveFailed = false;

  counterOfferCommentIsOpen = true;
  printMode = false;

  viewModels: UnderwriterViewModel[] = [];

  currentViewModel: UnderwriterViewModel = null;

  currentProperty: ListingProperty;
  currentAvmProforma: ProFormaModel;
  currentUwProforma: ProFormaModel;

  private _copyLabel = '';

  duplicateListings: ListingProperty[] = [];

  selectOptions = [
    {id: UNDERWRITER_ACTIONS.READY, label: UNDERWRITER_OPTIONS_LABEL.SEND_TO_TM, disabled: false, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM },
    {id: UNDERWRITER_ACTIONS.HIDE, label: UNDERWRITER_OPTIONS_LABEL.HIDE, disabled: true, main: false, actionID: UNDERWRITER_COMMANDS_NAME.sendTM }
  ];
  mainAction = [
    {id: UNDERWRITER_ACTIONS.DRAFT, label: UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT, disabled: true, main: true, actionID: UNDERWRITER_COMMANDS_NAME.saveDraft }
  ];

  mainActionDisabled = false;
  optionalActionDisabled = false;

  currentIdx = 0;
  currentTabIdx = 0;

  messageOnSave = null;

  underwriterNote: IUnderwriterNote = {
    model: {
      oldValue: null,
      newValue: null,
    },
    required: true,
    placeHolder: 'Add comment (optional)',
    type:'comment',
    hasError: false,
    disabled: false
  };
  underwriterRejectionReasons: IRejectionComment;

  missingRejectionReasons = false;

  isSlidePanelOpened = true;

  closedClass ='closed';
  underwriterContentClass ='underwriterContent-hh';
  activeProformaclosedClass ='closed';
  activeProformaunderwriterContentClass = 'underwriterContent-hh';

  isPanelOpened = false;

  isDownloading = false;

  isBusy = false;
  isLoading = false;

  isRequestUpdating = false;

  isManualLoading = false;

  propertyIdsTree: any = [];

  direction: navigationDirection = null;

  loadPropertyRetry = 0;

  PropertyStatusType = PropertyStatusType;
  columnsSettings: ColumnsSettings = defaultColumnsSettings;

  hasCompareChanged = false;
  selectedRentCompsAvg = 0;

  selectedEntityOptions: ITargetOption[] = [];

  canProcessExternalRequests = true; // eventBus request

  underwriteActionName = 'Underwrite';

  editLocked = false;

  // concessionNote = null;

  theForm: FormGroup;

  avmProformaVersioning: IProformaVersionModel;

  versionSource: PROFORMA_VERSION_SOURCE = PROFORMA_VERSION_SOURCE.FROM_PROFORMA;

  enable_versioning = true;

  updateRequests: IUpdateRequest = null;

  versionNames: string[] = [];

  sendToTransactionManager = false;

  private updateToast: ActiveToast<any>;
  private errorToast: ActiveToast<any>;

  private toastrOptionsError = {
    disableTimeOut: true,
    closeButton: true,
    enableHtml: true,
    messageClass: 'toast-message a1-toast-message',
    positionClass: "duplicate-sub",
    toastClass: 'duplicate-sub-toast ngx-toastr'
  };

  private enableAddressScrolling = false;
  private scrollPosition = {};
  private scrollTimeout;
  private addressScrollingThreshold = 80;
  private animationsDuration = 300;
  private closeOnHiddenView = false;
  uwPanels = UNDERWRITE_PANEL;

  checkMissingDataInException = false;

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private underwriterService: UnderwriterService,
    private router: Router,
    private location: Location,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private downloadService: DownloadService,
    private activityLogService: ActivityLogService,
    private updateRequestService: UpdateRequestService,
    private gridFilterService: GridFilterService,
    private toastr: ToastrService,
    private approvalService: AutoApprovalService,
    // ---> Remove this after the feature flag is removed from here
    private featureFlagsService: FeatureFlagsService,
    // ---> to here
    ) {
    this.downloadService.isDownloading.subscribe((isDownloading) => {
      this.isDownloading = isDownloading;
    });

    this.activatedRoute.data.subscribe(data => {
      this.viewMode = data['viewMode'];
      this.baseUrl = this.viewMode === ViewMode.BULK ? '/bulk' : '/underwriting';
    });

    this.approvalService.initEntitiesChangesDetection().subscribe ((v) => {
      if (this.currentUwProforma?.proforma) {
        this.onProformaChanges();
      }
    });

    this.theForm = this.fb.group({
      purchasingEntity: ['', []],
    });



    this.proformaChangesSubscriber = this.proformaChangesObservable
    .subscribe((v) => {
     if(v) {
      this.updateUnderwriterOptions();
      this.refreshOptionStats();
     }
    });
  }

  get counterOfferMessage(): string {
    return !JsUtils.isNullOrEmpty(this.currentProperty?.counterOfferMessage) ?
            this.currentProperty?.counterOfferMessage :
            'N/A';
  }

  get isPropertyCameFromTM() {
    return [PropertyStatusType.Counter, PropertyStatusType.NewPrice, PropertyStatusType.ConcessionUW, PropertyStatusType.CounterReason].includes(this.currentProperty?.underwritingStatus);
  }


  get propertyPager() {
    return this.propertyIds.length > 0 ? `${this.currentIdx + 1} of ${ this.propertyIds.length }` : this.currentIdx ? '1 of 1': '';
  }

  get selectedPurchasingEntityId () {
    if (this.currentUwProforma?.proforma.purchasingEntity) {
      const selectedOptions = this.selectedEntityOptions.filter( opt => {
        return opt.label == this.currentUwProforma?.proforma.purchasingEntity;
      });
      if(selectedOptions && selectedOptions[0]) {
        return selectedOptions[0].id;
      }
    }
    return this.approvalService.getDefaultEntityOption().id;
  }

  get copyLabel(): string {
    if(this.currentProperty?.copyID && this.currentProperty?.groupCount > 1) {
      this._copyLabel = this.currentProperty?.copyID > 1? `Copy ${this.currentProperty.copyID}` : 'Copy';
      this._copyLabel += ' - ';
      this._copyLabel += this.currentProperty?.source === ListingSources.AOListingView ? 'MLS' : 'Bulk';
    }
    return this._copyLabel;
  }

  set copyLabel(value: string) {
    this._copyLabel = value;
  }

  initialize() {
    try {
      if (!this._open || !this._propertyIds || this._propertyIds.length == 0) {
        this.currentProperty = null;
        return;
      }

      this.isLoading = true;
      this.isPanelOpened = true;
      this.currentTabIdx = 0;
      this.currentIdx = 0;
      this.currentViewModel = null;
      this.currentProperty = null;
      this.currentAvmProforma = null;
      this.avmProformaVersioning = null;
      this.currentUwProforma = null;

      this.viewModels = [];
      if(!this.isManualLoading && !this._propertyIds) {
        return;
      }

      this.propertyIdsTree = [];
      this._propertyIds.forEach(id => {
        this.propertyIdsTree.push(
          {
            id: id,
            hasError: false
          }
        );
      });

      this.loadProperty(this._propertyIds[0], true);
   } catch(e) {
    this.isLoading = false;
     console.log(e);
   }
  }

  async loadProperty(aoListingID, withNavigation: boolean, jumpToNextProperty: boolean = false) {
    let versionHistory: IProformaVersionModel;
    this.saveFailed = false;

    this.missingRejectionReasons = false;
    if ( !this.printMode && withNavigation && !_.isNil(aoListingID)) {
      const baseUrl = this.viewMode === ViewMode.BULK ? '/bulk' : '/underwriting';
      const url = this
      .router
      .createUrlTree([baseUrl, aoListingID])
      .toString();
      this.location.go(url);
      this.isManualLoading = true;
    }

    if(_.isNil(aoListingID) && this.isPanelOpened) {
      await this.handleNilProperty(aoListingID);
      return;
    }

    if(!this.isPanelOpened) {
      if(this.aoListingId == aoListingID) {
        return;
      }
      if(!withNavigation) {
        this.isPanelOpened = true;
      }
    }

    this.messageOnSave = null;

    if (this.currentUwProforma) {
      this.messageOnSave = this.currentUwProforma.messageOnSave;
    }

    this.aoListingId = aoListingID;

    // Check if we loaded it previously
    const alreadyLoaded = this.searchForProperty(aoListingID);
    this.duplicateListings = [];

    if (withNavigation && alreadyLoaded && this.propertyIdsTree[this.currentIdx] && !this.propertyIdsTree[this.currentIdx].hasError) {
      this.isLoading = false;
      const vm = this.currentViewModel = alreadyLoaded;

      this.currentProperty = vm.property;

      this.underwriterRejectionReasons = vm.underwriterRejectionReasons;
      this.currentAvmProforma = vm.avmProforma;
      this.avmProformaVersioning = vm.avmProformaVersioning;
      this.currentUwProforma = vm.uwProforma;
      this.updateRequests = this.currentViewModel.updateRequests;
      this.underwriterNote = vm.underwriterNote;
      this.duplicateListings= vm.duplicateListings;

      this.messageOnSave = this.currentUwProforma.messageOnSave;

      this.underwriteActionName = this.updateRequests?
      this.updateRequests?.activeStep?.saveLabel? this.updateRequests.activeStep.saveLabel : UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT : UNDERWRITER_OPTIONS_LABEL.SAVE_DRAFT;

      this.selectedEntityOptions= this.approvalService.updateSelectedEntityOptions( this.currentUwProforma.proforma);
      this.refreshOptionStats();

      this.proformaPanel.initialize(this.currentProperty, this.currentAvmProforma, this.currentUwProforma, this.currentViewModel.updateRequests, true, this.avmProformaVersioning);      this.checkConcessionFieldState(true);
      this.onProformaChanges();

      this.triggerUpdatePropertyPopup(this.currentProperty.AOListingID);
      if(this.updateRequests) {
        this.updateRequests.initUwReference(this);
      }
      if (this.currentProperty.groupCount > 1) {
        this.duplicateListings = (await this.fetchDuplicates(this.currentProperty.AOPropertyID, true))?.listings;
        const copyID = this.duplicateListings.filter(listing => listing.AOListingID === this.currentProperty.AOListingID)[0];
        if (copyID) {
          this.currentProperty.copyID = this.duplicateListings.filter(listing => listing.AOListingID === this.currentProperty.AOListingID)[0].copyID;
        }
      }
      this.isBusy = false;

    } else {
      if(this.propertyIdsTree[this.currentIdx] && this.propertyIdsTree[this.currentIdx].hasError) {
        await this.handleLoadingPropertyFailure(`Property: ${aoListingID} does not exists.`);
        return;
      }

      this.isBusy = true;
      this.theForm.disable({ emitEvent: false });

      try {
        this.isLoading = true;
        const property: ListingProperty = await this.underwriterService.getListingProperty(aoListingID);
        this.listingReady.emit(aoListingID);

        if (!property) {
          this.isLoading = false;
          await this.handleLoadingPropertyFailure(`Property: ${aoListingID} does not exists.`);
          return;
        }

        if(property.AOListingID != aoListingID) {
          if(this.loadPropertyRetry < 3) {
            ++this.loadPropertyRetry;
            await this.loadProperty(aoListingID, true);
          } else {
           await this.handleLoadingPropertyFailure();
          }
        }

        this.loadPropertyRetry= 0;

        // Speed up a bit by not making a call to the server if the listing is New.
        let uwProforma: ProFormaModel = null;
        if ((property.underwritingStatus != PropertyStatusType.New) || !property.proformaId) {
          let flowId;
          if([
            PropertyStatusType.PreInspectionReview,
            PropertyStatusType.PreInvestorApprovalUpdated,
            PropertyStatusType.PreInvestorApprovalReady
          ].includes(property.underwritingStatus)) {
            flowId = 'preInspection';
          }
          uwProforma = await this.underwriterService.getProforma(aoListingID, flowId);
          if (uwProforma.proforma) {
            uwProforma.pendingEvents = uwProforma.pendingEvents
              ? JSON.parse(uwProforma.pendingEvents) : null;
          }
        } else {
          uwProforma = new ProFormaModel();
          uwProforma.proforma = new ProForma();
          uwProforma.proforma.AOListingID = aoListingID;
        }
        // Wait until both are loaded to add to arrays


        this.currentProperty = property;
        const uqResponse = await this.underwriterService.queryUQAPI(
          this.currentProperty.AOListingID,
          this.currentProperty.purchasingEntity,
          this.currentProperty.Region,
          this.currentProperty.UQClassification
        );
        if (uqResponse) {
          this.currentProperty.minYieldRate = uqResponse.minYieldRate;
          this.currentProperty.minCapRate = uqResponse.minCapRate;
          this.currentProperty.renovationFeePercent = uqResponse.renovationFeePercent;
        }
        this.underwriterRejectionReasons = this.isPropertyRejectable();
        // fetch duplicates if we are loading a child
        if (this.currentProperty.groupCount > 1) {
          this.duplicateListings = (await this.fetchDuplicates(this.currentProperty.AOPropertyID, true))?.listings;
          const copyID = this.duplicateListings.filter(listing => listing.AOListingID === this.currentProperty.AOListingID)[0];
          if (copyID) {
            this.currentProperty.copyID = this.duplicateListings.filter(listing => listing.AOListingID === this.currentProperty.AOListingID)[0].copyID;
          }
        }

        this.onPropertyUpdated.emit([this.currentProperty, ...this.duplicateListings.filter(dp => {
          return dp.AOListingID != this.currentProperty.AOListingID;
        })]);

        this.currentAvmProforma = new ProFormaModel();

        this.currentAvmProforma.proforma = new ProForma();

        this.currentUwProforma = uwProforma;
        // call allocation api to get yield percentages

        this.selectedEntityOptions= this.approvalService.updateSelectedEntityOptions( this.currentUwProforma.proforma);
        this.updateRequests = this.canProcessExternalRequests
          ? _.cloneDeep(this.updateRequestService.checkForUpdate(this))
          : null;

        this.editLocked =!!this.updateRequests?.activeStep?.proforma?.editLocked;

        this.currentViewModel =  {
          property: this.currentProperty,
          avmProforma: this.currentAvmProforma,
          uwProforma: this.currentUwProforma,
          avmProformaVersioning: this.avmProformaVersioning,
          underwriterRejectionReasons: this.underwriterRejectionReasons,
          underwriterNote: this.underwriterNote,
          comps: [],
          compsFirstLoad: true,
          updateRequests: this.updateRequests,
          originalSelectedComps: null,
          duplicateListings: this.duplicateListings
        };

        this.underwriteActionName = this.updateRequests?
        this.updateRequests?.activeStep?.saveLabel? this.updateRequests.activeStep.saveLabel : UNDERWRITER_OPTIONS_LABEL.DRAFT : UNDERWRITER_OPTIONS_LABEL.UNDERWRITE;

        if(!this.searchForProperty(property.AOListingID)) {
          this.viewModels.splice(this.currentIdx, 0, this.currentViewModel);
        }

        this.checkConcessionFieldState();
        this.initUnderwriterNote(true);
        this.proformaPanel.initialize(this.currentProperty, this.currentAvmProforma, this.currentUwProforma, this.currentViewModel.updateRequests, true, this.avmProformaVersioning);

        if(this.currentViewModel.updateRequests) {
          this.updateRequests.initUwReference(this);
        }
        this.isLoading = false;
        this.isBusy = false;
        this.onProformaChanges();
        this.theForm.enable({ emitEvent: false });
        this.proformaUpdated = false;
      } catch (err) {
        this.isBusy = false;
        this.isLoading = false;
        this.theForm.enable({ emitEvent: false });
        console.log(err);

        this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
        this.errorToast.onTap.subscribe(() => {
          this.errorToast = null;
        });

        await this.handleLoadingPropertyFailure();
      }
    }
    // Load all the call async which are not dependent on initilization
    const promiseArray = [
      this.apiService.getNotificationsCount(this.aoListingId),
      this.activityLogService.getAuditHistory(aoListingID, this.versionSource),
    ]

    if (this.currentProperty.groupCount > 1) {
      promiseArray.push(this.fetchDuplicates(this.currentProperty.AOPropertyID));
    }
    const resp = await Promise.all(promiseArray);
    const [notifications, audit, duplicates = null] = resp;
    if (notifications && notifications[0]?.notViewed) {
      this.currentProperty.notesNotificationCount = notifications[0].notViewed;
    }
    versionHistory = this.enable_versioning ? audit : null;
    this.versionNames = versionHistory? versionHistory.versionNames : [];
    this.avmProformaVersioning = versionHistory? {
      source: this.versionSource,
      data: versionHistory.data,
      versionNames: this.versionNames
    }: null;

    if (this.avmProformaVersioning && !_.isEmpty(this.avmProformaVersioning)) {
      this.proformaPanel.buildProformaVersion(this.avmProformaVersioning);
      this.proformaPanel.versioning = true;
      this.proformaPanel.checkPreviousVersion(this.currentViewModel.updateRequests);
    }
    if (duplicates) {
      this.duplicateListings = (duplicates)?.listings;
      this.currentProperty.copyID = this.duplicateListings.filter(listing => listing.AOListingID === this.currentProperty.AOListingID)[0].copyID;
    }


    this.isManualLoading = false;
    if (jumpToNextProperty) {
      const indexOfCurrentProperty = this._propertyIds.findIndex( el => el == aoListingID);
        if (indexOfCurrentProperty === this._propertyIds.length -1) {
          setTimeout(()=> {this.close();}, this.animationsDuration);
        } else {
          setTimeout(()=> {this.next();}, this.animationsDuration);
        }
    }
  }

  private async handleNilProperty(aoListingID) {
    return new Promise(async (resolve) => {
      await this.onErrorLoadingMessage(`the property: ${aoListingID} is not Valid !`, false);
      setTimeout(async () => {
        if (this._propertyIds.length > 0) {
          await this.handleLoadingPropertyFailure();
          resolve(null);
        } else {
          this.close();
        }
        resolve(null);
      }, 500);

    });

  }

  private async onErrorLoadingMessage (message: string, modal: boolean = true) {
    if(modal) {
      const config = new ModalConfig();
      config.type = 'ok';
      config.title = 'Error loading property';
      config.text = message;
      config.okText = 'Close';
      await  this.modalService.open(config, 'ErrorModalComponent');
      return Promise.resolve();
    }

    this.errorToast = this.toastr.error(message, null, this.toastrOptionsError);
    this.errorToast.onTap.subscribe(() => {
      this.errorToast = null;
    });

  }
  private searchForProperty(aoListingID) {
    const propArray = this.viewModels.filter(vmItem => {
      return vmItem.property.AOListingID === aoListingID;
    });

    if (propArray && propArray[0]) {
      return propArray[0];
    }
    return undefined;

  }

  private async handleLoadingPropertyFailure(message:string = null, finalRetry: boolean = false) {
    let validId;
    if(message) {
      await this.onErrorLoadingMessage(message, false);
    }
   try {
    if(this.propertyIdsTree[this.currentIdx]) {
      this.propertyIdsTree[this.currentIdx].hasError = true;
    }
    if(!this.direction) {
      this.direction = 'asc';
    }

    if(this.direction === 'asc' && this.currentIdx + 1 < this._propertyIds.length) {
      for(let i = this.currentIdx +1; i <  this._propertyIds.length; i++) {
        this.currentIdx = i;

        // const url = this
        //             .router
        //             .createUrlTree(['/underwriting', this.propertyIdsTree[i].id])
        //             .toString();
        //             this.location.go(url);

        if(!this.propertyIdsTree[i].hasError) {
          validId = i;
          break;
        } else {
          const msg = this.propertyIdsTree[i].id? `Property: ${this.propertyIdsTree[i].id} does not exists.`: `the property: ${this.propertyIdsTree[i].id} is not Valid !`;

          this.errorToast = this.toastr.error(msg, null, this.toastrOptionsError);
          this.errorToast.onTap.subscribe(() => {
            this.errorToast = null;
          });
        }
      }
      if(validId) {
        await this.loadProperty(this.propertyIdsTree[validId].id, true);
        return;
      } else {
        this.direction = 'desc';
      }

    }
    if(this.direction === 'desc' && this.currentIdx >= 0) {
        for(let i= this.currentIdx-1; i >= 0 ; i--) {
          this.currentIdx = i;

          // const url = this
          //             .router
          //             .createUrlTree(['/underwriting', this.propertyIdsTree[i].id])
          //             .toString();
          //             this.location.go(url);

          if(!this.propertyIdsTree[i].hasError) {
           validId = i;
            break;
          } else {
            const msg = this.propertyIdsTree[i].id? `Property: ${this.propertyIdsTree[i].id} does not exists.`: `the property: ${this.propertyIdsTree[i].id} is not Valid !`;
            this.errorToast = this.toastr.error(msg, null, this.toastrOptionsError);
            this.errorToast.onTap.subscribe(() => {
              this.errorToast = null;
            });
          }
      }

      if(!_.isNil(validId)) {
        await this.loadProperty(this.propertyIdsTree[validId].id, true);
        return;
      } else {
        this.direction = 'asc';
      }

    }

    if(_.isNil(validId) && !finalRetry) {
      await this.handleLoadingPropertyFailure(message,true);
      return;
    } else {
      this.close();
    }

   } catch(err) {
    this.errorToast = this.toastr.error('error when trying to resolve loading error(s)', null, this.toastrOptionsError);
    this.errorToast.onTap.subscribe(() => {
      this.errorToast = null;
    });
    this.close();
   }

  }

  closeClick() {
    const activeActions = this.getActiveActions();

    if (activeActions.length == 0) {
      this.close();
      return;
    }

    const unsavedChangesCallback = (vm: UnderwriterViewModel) => (vm.uwProforma.isDirty && !vm.uwProforma.automaticUpdate)
    || vm.isDirty
    || this.hasCompareChanged
    || (vm.underwriterRejectionReasons && vm.underwriterRejectionReasons.updated)
    || (
        vm.underwriterRejectionReasons
        && vm.underwriterRejectionReasons.active
        && vm.underwriterRejectionReasons.reasonMode
        && (!vm.underwriterRejectionReasons.reason.newValue || !vm.underwriterRejectionReasons.reason.newValue.length)
        && (!vm.underwriterRejectionReasons.reason.oldValue || !vm.underwriterRejectionReasons.reason.oldValue.length)
      )
     || (vm.underwriterNote && (vm.underwriterNote.model.newValue != vm.underwriterNote.model.oldValue))
      ;


    const unsavedViewModels: UnderwriterViewModel[] = this.viewModels.filter(unsavedChangesCallback);

    if (unsavedViewModels && unsavedViewModels.length) {
      const firstUnsavedViewModel = unsavedViewModels[0];
      if (this.currentProperty.AOListingID != firstUnsavedViewModel.property.AOListingID) {
        this.currentIdx = this.propertyIds .findIndex((id) => id ==  firstUnsavedViewModel.property.AOListingID);
        this.loadProperty(firstUnsavedViewModel.property.AOListingID, true).then(()=> {
          this.handleUnsavedChanges(firstUnsavedViewModel);
        });

      } else {
        this.handleUnsavedChanges(firstUnsavedViewModel);
      }

      const config = new ModalConfig();
      config.type = 'yesno';
      config.title = 'You have unsaved changes';
      config.text = 'Closing the underwriting view will delete unsaved changes for all properties in your queue.';
      config.cancelText = 'Keep editing';
      config.okText = 'Close';

      this.modalService.open(config).then((result) => {
        this.onConfirmClosed(result);
      });

    } else {
      this.close();
    }
  }

  private getActiveActions() {
    const activeActions = [];
    Object.keys(this.updateRequests?.activeStep?.actions || {}).forEach(ac => {
      if (this.updateRequests?.activeStep?.actions[ac].active) {
        activeActions.push(ac);
      }
    });
    return activeActions;
  }

  onConfirmClosed(result) {
    if (result == ModalComponent.OK) {
      this.close();
    }
  }

  close() {
    this.isPanelOpened = false;
    this.closed.emit(true);

    this.viewModels = [];

    this.currentViewModel = null;
    this.currentProperty = null;
    this.currentAvmProforma = null;
    this.avmProformaVersioning = null;
    this.currentUwProforma = null;
    this.updateRequests = null;

    this.currentIdx = 0;
    this.currentTabIdx = 0;

    this.hasCompareChanged = false;

    this.aoListingId = null;
    this.isLoading = false;

    this.removeToast();
    this.proformaChangesSubscriber.unsubscribe();
    this.location.go(this.baseUrl);
  }

  previous() {
    this.isBusy = true;
    this.removeToast();
    if (this.currentIdx > 0) {
      this.isManualLoading = true;
      this.currentIdx--;
      this.direction = 'desc';
      this.initContainerWidth();
      this.loadProperty(this._propertyIds[this.currentIdx], true);
    }
  }

  next() {
    this.isBusy = true;
    this.removeToast();
    if (this.currentIdx < this._propertyIds.length -1) {
      this.isManualLoading = true;
      this.currentIdx++;
      this.direction = 'asc';
      this.initContainerWidth();
      this.loadProperty(this._propertyIds[this.currentIdx], true);
    }
  }

  initContainerWidth() {
    this.closedClass ='closed';
    this.underwriterContentClass ='underwriterContent-hh';
    this.activeProformaclosedClass ='closed';
    this.activeProformaunderwriterContentClass = 'underwriterContent-hh';
  }

  async checkIfSendToTMWithFewComps(checked) {
    if (
      this.currentProperty.status < PropertyStatusType.OfferReady
      && ((this.currentUwProforma.selectedRentComparableIds.length < 3
        || this.currentUwProforma.selectedSaleComparableIds.length < 3)
        && this.currentUwProforma.selectedActiveComparableIds.length == 0)
      && !this.currentUwProforma.reasonFewComps
      && !checked
    ) {
      return await this.triggerSendToTMFewCompsModal();
    } else {
      return Promise.resolve(true);
    }
  }

  async triggerSendToTMFewCompsModal() {
    const config = new ModalConfig();
    config.type = 'yesno';
    config.title = 'You need to select more comps';
    config.text = 'In order to send the property to the transaction manager, you need to select at least (3) rental comps and (3) sale comps. If you cannot select the minimum requirement, provide a reason below.';
    config.cancelText = 'Cancel';
    config.okText = 'Save';
    config.width = '596px';

    return new Promise((resolve) => {
      this.modalService.open(config, 'SendToTMFewCompsModalComponent')
      .then((result) => {
        if (result.action === 'cancel') {
          resolve(false);
        }
        this.currentUwProforma.reasonFewComps = result.comment;
        resolve(true);
      })
      .catch((e) => {
        resolve(false);
      });
    });
  }

  async triggerWarningBusinessRules(warningFields) {
    const config = new ModalConfig();
    config.type = 'yesno';
    config.title = 'Highest Purchase Price Offer higher than Seller Counter';
    config.text = 'The Highest Purchase Price you have entered is more than the price that the seller has countered with. Do you wish to confirm this price or cancel?';
    config.okText = 'Confirm';
    config.cancelText = 'Cancel';
    config.width = '601px';

    config.options = {
      onWarning: warningFields
    };

    const result = await this.modalService.open(config, 'BusinessRuleWarningComponent').catch(err => {
      return false;
    });

    return result !== 'cancel';
  }

  async checkforBusinessWarning (checked) {
    const warningFields = this.proformaPanel.checkFieldsForWarning(false);
    if (warningFields && warningFields.length) {
      return this.triggerWarningBusinessRules(warningFields);
    }
    return true;

  }

  async triggerSendToTMMissingRequiredFields() {
    const config = new ModalConfig();
    config.type = 'yesno';
    config.title = 'Send to transaction manager';
    config.text = 'This will send the property to the transaction manager board. The property will be marked "Offer Ready".';
    config.okText = 'Send';
    config.width = '596px';

    this.modalService.open(config, 'MissingFieldsModalComponent').then((result) => {

    if (result.action === 'cancel') {
      return;
    }

    this.currentUwProforma.proforma.purchasingEntity = result.purchasingEntity;
    this.currentUwProforma.userInputProforma.purchasingEntity = result.purchasingEntity;
    this.save(UNDERWRITER_ACTIONS.READY);
    });
  }

  /**
   *
   * @returns Promise
   * @description investor Approuval panel
   */
  async triggerSendToInvestorApproval(options?: IApproval): Promise<any> {
    return new Promise((resolve, reject) => {
      const config = new ModalConfig();
      config.type = 'yesno';
      config.title = 'Send to transaction manager';
      config.text = 'This will send the property to the transaction manager board. Please indicate below if you received investor approval".';
      config.okText = 'Send';
      config.width = '596px';
      config.options = {
        approval:options
      };

      if (!!!this.currentUwProforma.proforma.investorApproval) {
        this.modalService.open(config, 'InvestorApprovalComponent')
          .then((result) => {

            if (result.action === 'cancel') {
              return resolve({approval: 'cancel'});
            }
            this.currentUwProforma.proforma.investorApproval = result.investorApproval;
            if (!!!result.investorApproval) {
              return resolve({approval:'no',reason: result.reason});
            }
            return resolve({approval: true});
          })
          .catch((error) => {
            reject(error);
          });

      } else {
        resolve({approval: true});
      }
    });
  }


  async triggerDuplicateSubmission(onHold: IOnHold) {
    const config = new ModalConfig();
    config.type = 'yesno';
    config.title = 'Property cannot be saved';
    config.text = 'You cannot modify ';
    config.okText = 'Continue';
    config.cancelText = 'Go back';
    config.width = '596px';
    config.options = { onHold: [onHold] };

    const result = await this.modalService.open(config, 'DuplicateSubmissionComponent').catch(err => {
      return false;
    });
    if (result === 'cancel') {
      return false;
    }
    return true;
  }

  async triggerDuplicatePropertySent(sentToTMUser, sentToTMDate) {
    const config = new ModalConfig();
    config.type = 'yesno';
    config.title = 'Duplicate property already sent';
    config.okText = 'Send anyway';
    config.cancelText = 'Go back';
    config.width = '601px';
    config.options = {
      agentName: sentToTMUser,
      editedTime: sentToTMDate,
    };

    const result = await this.modalService.open(config, 'DuplicatePropertySentComponent').catch(err => {
      return false;
    });

    return result !== 'cancel';
  }

  async triggerMissingPropertyData(missingDataIncludedInException) {
    const config = new ModalConfig();
    config.type = 'yesno';
    config.title = 'You need to add missing data';
    config.text = 'In order to send the property to the transaction manager, you need to fill out missing property characteristics.';
    config.okText = 'Save';
    config.cancelText = 'Cancel';
    config.width = '596px';
    config.options = {
      listingData: this.currentProperty,
      exceptionEntries: missingDataIncludedInException
    };

    const result = await this.modalService.open(config, 'MissingPropertyValuesComponent').catch(err => {
      return false;
    });

    if (result.action === 'cancel') {
      return false;
    }
    return result;
  }

  checkForPendingEvents($event, eventBus) {
    const allPendingEvents: ITriggerEVBusEvent[] = [];
    const actionId =this.getRequestedAction($event);
    if (this.updateRequests.activeStep?.actions[actionId]?.triggerEVBusEvent) {
      allPendingEvents.push(this.updateRequests?.activeStep?.actions[actionId].triggerEVBusEvent);
    }

    // allPendingEvents.push(this.updateRequests.activeStep.commands.panel.triggerConcessionFields.triggerEVBusEvent);

    eventBus = eventBus || [];
    allPendingEvents.forEach((triggerEVBusEvent) => {
      if (
        triggerEVBusEvent
        && triggerEVBusEvent.checkForPendingEvents
        && triggerEVBusEvent.checkForPendingEvents.active
      ) {
        triggerEVBusEvent.checkForPendingEvents.validators.forEach(vd => {
          vd(this, $event, eventBus, triggerEVBusEvent.checkForPendingEvents);
        });
      }
    }
    );

    return eventBus;
  }

  isExceptionIncludesMissingData(missingDataEntries: any[]) {
    const needUpdate = [];
    if (!this.currentProperty.Exception || !this.checkMissingDataInException) {
      return needUpdate;
    }

    missingDataEntries.forEach(entry => {
      const exceptionValidator = MissingData[entry].exceptionValidator ? new RegExp(MissingData[entry].exceptionValidator, 'gmi') :  false;
      if (exceptionValidator && exceptionValidator.test(this.currentProperty.Exception)) {
        needUpdate.push(entry);
      }
    });

    return needUpdate;
  }


  async checkForMissingData () {
    if (this.currentProperty.status > PropertyStatusType.Underwritten) {
      return true;
    }

    const missingDataEntries =  Object.keys(MissingData);
    const isDataMissing = _.some(missingDataEntries, (prop) => {
      return !!!this.currentProperty[prop];
    });

    const missingDataIncludedInException = this.isExceptionIncludesMissingData(missingDataEntries);

    if (isDataMissing || this.currentProperty.missingData || (missingDataIncludedInException.length && !this.currentProperty.isListingManuallyUpdated)) {
      const result = await this.triggerMissingPropertyData(missingDataIncludedInException);
      if (!!!result) {
        return false;
      } else {
        this.currentUwProforma.listingData = this.currentUwProforma.listingData  || {};
        this.currentUwProforma.listingData = {...this.currentUwProforma.listingData, ...result.updatedListing};
       return true;
      }
    }
    return true;
  }

  async save($event, checked: boolean = false) {
    let versioning = {
      versionName: null,
      update: false
    };
    let eventBus;
    const currentMinYieldRate = this.currentProperty.minYieldRate;
    const currentMinCapRate = this.currentProperty.minCapRate;
    const currentRenovationFeePercent = this.currentProperty.renovationFeePercent;

    const checkForMissingRequiredFields = this.proformaPanel.checkForMissingRequiredFields(true).isNotValid;
    if ([UNDERWRITER_ACTIONS.READY, UNDERWRITER_ACTIONS.DRAFT, UNDERWRITER_ACTIONS.APPROVAL].includes($event)) {
      if (![VALIDATION_ERROR_TYPE.NONE, VALIDATION_ERROR_TYPE.WARN].includes(checkForMissingRequiredFields)) {
        return;
      }
    }

    if ([UNDERWRITER_ACTIONS.DRAFT, UNDERWRITER_ACTIONS.READY].includes($event) && (VALIDATION_ERROR_TYPE.WARN === checkForMissingRequiredFields) && ! await this.checkforBusinessWarning(checked)) {
      return;
    }

    if (this.checkUnderwriterNoteRules()) {
      return;
    }

    // send To TM checks
    if ($event === UNDERWRITER_ACTIONS.READY) {

      // step1 - check if duplicate submission
      if (this.duplicateListings.length) {
        const duplicateDecision = await this.handleDuplicateListingsSubmission();
        if (!duplicateDecision) {
          this.saveFailed = true;
          return;
        }
      }

      // step2 - check if Few comps
      if (!await this.checkIfSendToTMWithFewComps(checked)) {
        return;
      }

      // step3 - check if missing data
      if (! await this.checkForMissingData()) {
        return;
      }
    }

    if (!this.checkIfCanSendToTM($event)) {
      return;
    }

    this.isBusy = true;
    this.theForm.disable({ emitEvent: false });

    if (this.updateRequests) {
      const checkResult = await this.checkForNewStatusAndVersioningForUpdateRequestII($event, versioning);
      if (checkResult.notImplemented) {
        console.log('this action is not implemented');
        return;
      }
      if (checkResult.approval.approval == UNDERWRITER_ACTIONS.CANCEL) {
        return;
      } else {
        versioning = checkResult.versioning;
        eventBus = this.checkForEventBusRequestEvent($event);
        if(versioning && !versioning.versionName) {
          versioning = null;
        }
      }
      eventBus = this.checkForPendingEvents($event, eventBus);
    } else {
      this.currentUwProforma.workFlowNote = null;
    }

    this.checkIfSendToTransactionManagerAction($event);

    this.currentUwProforma.listingData.CompsMapCenter = `${this.comparablesPanel.map.getCenter().lat()},${this.comparablesPanel.map.getCenter().lng()}`;
    this.currentUwProforma.listingData.CompsMapZoom = this.comparablesPanel.map.getZoom();
    this.currentUwProforma.listingData.isHeaderRow = this.currentProperty.isHeaderRow;
    this.currentUwProforma.listingData.groupCount = this.currentProperty.groupCount;
    this.saveStart = true;

    this.animateSave();

    this.currentUwProforma.proforma.investorApproval = !_.isNil(this.currentUwProforma.proforma.investorApproval)?
      this.currentUwProforma.proforma.investorApproval
      : 0;

    try {
      let result;
      if ([UNDERWRITER_ACTIONS.SHOW, UNDERWRITER_ACTIONS.UNDO_APPROVAL].includes($event)) {
        let revertStatus;
        if (UNDERWRITER_ACTIONS.SHOW == $event) {
          revertStatus = this.currentUwProforma.listingStatus || PropertyStatusType.New;
        } else {
          revertStatus = this.handleStatusForUndoAction($event) ?? this.currentUwProforma.listingStatus;
        }
        result = await this.underwriterService.savePropertyStatus([this.currentProperty], true, revertStatus);
        const isOnHold = await this.isListingOnHold(result);
        if (isOnHold) { return; }

        result.updatedProperty = await this.underwriterService.getListingProperty(this.currentProperty.AOListingID);
        this.currentProperty = result.updatedProperty? result.updatedProperty : this.currentProperty;
        this.resetProformaChanges();
      } else {
        this.currentProperty.workflowState = WORKFLOW_STATES[this.currentProperty.status] ? WORKFLOW_STATES[this.currentProperty.status] : null;
        this.currentUwProforma.reasonFewComps = $event == UNDERWRITER_ACTIONS.READY?  this.currentUwProforma.reasonFewComps : null;

        result = await this.underwriterService.saveProforma(this.currentUwProforma, this.currentAvmProforma, versioning, eventBus, this.currentProperty.StatusUpdateDate, this.currentProperty.workflowState);

        const isOnHold = await this.isListingOnHold(result);
        if (isOnHold) { return; }

        this.currentUwProforma = result.proformaModel;
        this.selectedEntityOptions= this.approvalService.updateSelectedEntityOptions( this.currentUwProforma.proforma);

        if (this.currentUwProforma.proforma) {
          this.currentUwProforma.pendingEvents = this.currentUwProforma.pendingEvents
            ? JSON.parse(this.currentUwProforma.pendingEvents) : null;
        }

        const copyID = this.currentProperty.copyID;
        this.currentProperty = result.updatedProperty? result.updatedProperty : this.currentProperty;
        this.currentProperty.purchasingEntity = this.currentUwProforma.proforma.purchasingEntity;
        this.currentProperty.copyID = copyID;

        this.currentUwProforma.messageOnSave = this.messageOnSave = null;
        const versionHistory: IProformaVersionModel = this.enable_versioning ? await this.activityLogService.getAuditHistory(this.currentProperty.AOListingID, this.versionSource) : null;
        this.versionNames = versionHistory? versionHistory.versionNames : [];

        this.avmProformaVersioning = versionHistory? {
          source: this.versionSource,
          data: versionHistory.data,
          versionNames: this.versionNames
        }: null;
      }

      this.proformaPanel.forceUpdate = false;

      this.updateRequests = this.canProcessExternalRequests?
        _.cloneDeep(this.updateRequestService.checkForUpdate(this))
        : null;

      this.editLocked =_.get(this.updateRequests,'activeStep.proforma.editLocked', this.editLocked);
      this.currentViewModel.updateRequests =  this.updateRequests;

      this.currentViewModel.originalSelectedComps = {
        rent: this.currentUwProforma.selectedRentComparableIds.map(rentIds=> {
          return parseInt(rentIds, 10);
        }),
        sale: this.currentUwProforma.selectedSaleComparableIds.map(saleIds=> {
          return parseInt(saleIds, 10);
        }),
        active: this.currentUwProforma.selectedActiveComparableIds.map(saleIds=> {
          return parseInt(saleIds, 10);
        })
      };

      this.currentUwProforma.initialProforma = null;

      this.currentProperty.minYieldRate = currentMinYieldRate;
      this.currentProperty.minCapRate = currentMinCapRate;
      this.currentProperty.renovationFeePercent = currentRenovationFeePercent;

      this.proformaPanel.initialize(this.currentProperty, this.currentAvmProforma, this.currentUwProforma, this.currentViewModel.updateRequests, false, this.avmProformaVersioning);
      this.checkConcessionFieldState();

      this.currentViewModel.underwriterRejectionReasons = this.underwriterRejectionReasons = this.isPropertyRejectable({newValue: this.underwriterRejectionReasons?.reason?.newValue, updated: false});
      this.initUnderwriterNote(true);

      if(this.updateRequests) {
        this.updateRequests.initUwReference(this);
      }


      if (this.currentProperty.groupCount > 1) {
        this.duplicateListings = (await this.fetchDuplicates(this.currentProperty.AOPropertyID))?.listings;
        this.viewModels[this.currentIdx].duplicateListings = this.duplicateListings;
      }
      this.viewModels[this.currentIdx].property = result.updatedProperty? result.updatedProperty : this.currentProperty;
      this.viewModels[this.currentIdx].avmProformaVersioning = this.avmProformaVersioning;
      this.onPropertyUpdated.emit([this.currentProperty, ...this.duplicateListings.filter(dp => {
        return dp.AOListingID != this.currentProperty.AOListingID;
      })]);

      const isQueueEmpty = this.updatePropertiesQueue(result);
      if (isQueueEmpty) {
        return;
      }

      this.isBusy = false;
      this.onProformaChanges();
      this.theForm.enable({ emitEvent: false });
      this.proformaUpdated = false;
      this.hasCompareChanged = false;
      this.refreshOptionStats();
    } catch (error) {
      this.isBusy = false;
      this.isLoading = false;
      this.theForm.enable({ emitEvent: false });

      this.saveStart = false;
      this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
      console.log(error);
      this.errorToast.onTap.subscribe(() => {
        this.errorToast = null;
      });
      console.error(error);
    }
  }

  handleStatusForUndoAction($event) {
    const actionId = this.getRequestedAction($event);
    return this.updateRequests?.activeStep?.actions[actionId].updateStatus.fallBackStatus;
  }

  updatePropertiesQueue(result) {
    let isQueueEmpty = false;
    const isInHiddenView = this.closeOnHiddenView ? this.gridFilterService.getShowHiddenFlag(this.viewMode === ViewMode.MAESTRO ? 'propertyGrid' : 'bulkPropertyGrid'): false;
    const isListingHidden = result.updatedProperty.isHiddenForAll;
    if (
      (isInHiddenView && !isListingHidden) ||
      (!isInHiddenView && isListingHidden)
    ) {
      const isLastPropertyInQueue = this._propertyIds.length === this.currentIdx +1;
      this._propertyIds.splice(this.currentIdx, 1);
      this.viewModels.splice(this.currentIdx, 1);

      if (this._propertyIds.length > 0) {
        if (isLastPropertyInQueue) {
          this.previous();
        } else {
          this.currentIdx--;
          this.next();
        }
      } else {
        this.close();
        isQueueEmpty = true;
      }
    } else if (this._propertyIds.length > 0) {
      this.next();
    }
    return isQueueEmpty;
  }

  checkUnderwriterNoteRules() {
    if (this.underwriterRejectionReasons && this.underwriterRejectionReasons.active) {
      if (
        this.checkRejectionCommentErrors(false)
        ) {
          return true;
      }

      this.currentUwProforma.listingData.rejectionReasons = {
        updated: this.underwriterRejectionReasons.updated,
        newValue: this.underwriterRejectionReasons.reason.newValue
      };
    }

    if (['concession', 'preInspection'].includes(this.underwriterNote.type)) {
      if(!this.underwriterNote.model.newValue && this.underwriterNote.type === 'concession') {
        this.underwriterNote.hasError = true;
        return true;
      }

    this.currentUwProforma.workFlowNote.newValue = this.underwriterNote.model.newValue;
    return false;
    }
    this.currentUwProforma.messageOnSave = this.underwriterNote.model.newValue;
     return false;
  }

  private async isListingOnHold(result: { onHold?: IOnHold; updatedProperty?: ListingProperty; proformaModel?: ProFormaModel }) {
    if (result.onHold) {
      this.saveFailed = true;
      const lastUpdateIdentity = result?.onHold?.user;
      this.currentUwProforma.onHold = {
        user: `${lastUpdateIdentity ?
          (lastUpdateIdentity.firstName || lastUpdateIdentity.lastName) ? lastUpdateIdentity.firstName + (lastUpdateIdentity.lastName ? ' ' + lastUpdateIdentity.lastName : '') : 'because there was an update'
          : 'because there was an update'}`,
        lastUpdateDate: result?.onHold?.lastUpdateDate
      };
      const userDecision = await this.triggerDuplicateSubmission(this.currentUwProforma.onHold).catch(err => {
        return;
      });

      this.saveStart = false;
      if (!userDecision) {
        this.isBusy = false;
        this.triggerUpdatePropertyPopup(this.currentProperty.AOListingID);
        const listing = await this.underwriterService.getListingProperty(this.currentProperty.AOListingID);
        listing.copyID = this.currentProperty.copyID;
        this.onPropertyUpdated.emit([listing]);
        return true;
      } else {
        this.currentUwProforma.onHold = null;
        this.saveFailed = false;
        this.reloadProperty(this.currentProperty.AOListingID, true);
        return true;
      }
    }

    return false;
  }

  private reloadProperty(aoListingId: string, jumpToNextProperty: boolean = false) {
    const vmIndex = this.viewModels.findIndex(vm => {
      return vm.property.AOListingID == aoListingId;
    });
    if (vmIndex > -1) {
      this.viewModels.splice(vmIndex, 1);
    }
    this.loadProperty(aoListingId, false, jumpToNextProperty);

  }






private checkIfSendToTransactionManagerAction($event) {
  if([UNDERWRITER_ACTIONS.CONTINUE, UNDERWRITER_ACTIONS.READY].includes($event) || this.currentUwProforma.sendToTransactionManager) {
    this.sendToTransactionManager = true;
  } else {
    this.sendToTransactionManager = false;
  }
  this.currentUwProforma.sendToTransactionManager = this.sendToTransactionManager;
}

  // Minimum delay for animation is 300ms.
  // if not done by then, continue with 50ms intervals
  animateSave(delay = this.animationsDuration) {

    setTimeout(() => {
      if (this.isBusy) {
        this.animateSave(50);
      } else {
        this.saveStart = false;
      }
    }, delay);
  }

  isSaveDisabled(): boolean {
    if(this.updateRequests && this.updateRequests?.activeStep?.activeAction?.saveDraft) {
      return false;
    }
    if(this.updateRequests && !this.updateRequests?.activeStep?.actions?.saveDraft?.active) {
      return true;
    }
    return !this.canSaveComparables() &&
      (
        !this.currentProperty ||
        this.isBusy ||
        !this.isUnderwritterDirty()
      );
  }

  isOptionsDisabled(): boolean {
    if(this.updateRequests && this.updateRequests?.activeStep?.activeAction?.sendTM) {
      return false;
    }
    if(this.updateRequests && this.updateRequests?.activeStep?.DisableHideForEveryOne && !this.updateRequests?.activeStep?.actions?.sendTM?.active) {
      return true;
    }
    return this.currentProperty?.underwritingStatus !== PropertyStatusType.Underwritten &&
      !this.canSaveComparables() &&
      (
        !this.isUnderwritterDirty() &&
        !this.proformaUpdated
      );
  }

  private updateHideForAllDisabled() {
    if(this.updateRequests?.activeStep?.DisableHideForEveryOne) {
      this.selectOptions[1].disabled = true;
      return;
    }
    this.selectOptions[1].disabled = [
      PropertyStatusType.Counter,
      PropertyStatusType.ConcessionToUW,
      PropertyStatusType.NewPrice,
      PropertyStatusType.OfferReady,
      PropertyStatusType.OfferReadyReason,
      PropertyStatusType.OfferReadyUrgent,
    ].includes(this.currentProperty.underwritingStatus);
  }

  private isUnderwritterDirty(): boolean {
    return !!this.currentUwProforma?.isDirty ||
    !!this.currentViewModel?.isDirty ||
    (
      !!this.messageOnSave && !_.isEmpty(this.messageOnSave.trim())
    );
  }

  onShowTabClick(idx) {
    if (this.currentTabIdx != idx) {
      this.isSlidePanelOpened = true;
    } else {
      this.isSlidePanelOpened = !this.isSlidePanelOpened;
    }

    if (this.currentProperty && this.currentProperty.isNotesTabViewed) {
      this.currentProperty.notesNotificationCount = 0;
    }
    this.currentTabIdx = idx;

    if (this.isSlidePanelOpened) {
      if (this.enableAddressScrolling && idx === this.uwPanels.PROFORMA_PANEL) {
        this.isAddressVisible = true;
        this.onProformaScroll(false, idx);
      } else {
        this.isAddressVisible = true;
      }
    }

    if (idx === this.uwPanels.PROFORMA_PANEL) {
      this.underwriterContentClass = this.activeProformaunderwriterContentClass;
      this.closedClass = this.activeProformaclosedClass;
    } else {
      this.underwriterContentClass ='underwriterContent-hh';
      this.closedClass = 'closed';
    }
  }

  savePropertyDetails(updated: PropertyDetailsSaveValues) {
    this.underwriterService.saveProperty(this.currentProperty, { [updated.field]: updated.value });
  }

  download() {
    if (!this.isDownloading) {
      this.downloadService.downloadProperties(this.aoListingId)
      .then(propertyExcel => {
        downloadFile(propertyExcel.body.data as ArrayBuffer, propertyExcel.contentType, propertyExcel.filename, propertyExcel.body.data.length);
      })
      .catch(err => {
        this.errorToast = this.toastr.error('Something went wrong', null, this.toastrOptionsError);
        console.log(err);
        this.errorToast.onTap.subscribe(() => {
          this.errorToast = null;
        });
        this.isDownloading = false;
      });
    }
  }

  getTags() {
    const tags = _.get(this.currentProperty, 'Tags', false);
    if (tags) {
      const tagsObject = tags;
      const tagArray = [];
      if (tagsObject) {
        Object.keys(tagsObject).forEach(tag => {
          tagArray.push(tagsObject[tag]);
        });
      }
      if (tagArray) {
        return _.sortBy( tagArray, [tag => tag.toLowerCase()] );
      } else {
        return false;
      }
    }
    return false;
  }

  onCompareChanged() {
    const selectedRentComparables = this.comparablesPanel.comparables.filter(comparable => this.currentUwProforma.selectedRentComparableIds.includes(comparable.AOCompID));

    const isRentCompsChanged = !_.isEqual(this.currentUwProforma.selectedRentComparableIds.map(rentIds=> {
      return parseInt(rentIds, 10);
    }), this.currentViewModel?.originalSelectedComps?.rent || []);

    const isMonthlyRentAmountOverwritten = this.currentUwProforma.originalProforma?.userOverwrittenFields?.includes('monthlyRentAmount');
    const isNewStatus = this.currentProperty?.underwritingStatus <  PropertyStatusType.Underwritten ||  !!this.currentProperty?.underwritingStatus;
    if((!isRentCompsChanged && !this.hasCompareChanged) || isMonthlyRentAmountOverwritten || !isNewStatus) {
      this.hasCompareChanged = isRentCompsChanged;
      this.refreshOptionStats();
      return;
    }

    const isSaleCompsChanged = !_.isEqual(this.currentUwProforma.selectedSaleComparableIds.map(saleIds=> {
      return parseInt(saleIds, 10);
    }), this.currentViewModel?.originalSelectedComps?.sale || []);

    const isActiveCompsChanged = !_.isEqual(this.currentUwProforma.selectedActiveComparableIds.map(activeIds=> {
      return parseInt(activeIds, 10);
    }), this.currentViewModel?.originalSelectedComps?.active || []);

    if (
      !isRentCompsChanged
      &&
      !isSaleCompsChanged
    ) {
      this.hasCompareChanged = false;
    } else {
      this.hasCompareChanged = true;
    }

    this.proformaPanel.focusOnMonthlyRentInput();

    this.selectedRentCompsAvg = selectedRentComparables.length
      ? Math.round(
        selectedRentComparables.reduce((totalRents, comparable) => {
          totalRents += comparable.LastRent;
          return totalRents;
        }, 0) / selectedRentComparables.length
      )
      : 0;
      this.refreshOptionStats();
  }

  private canSaveComparables(): boolean {
    return this.hasCompareChanged &&
    this.currentProperty &&
    this.currentProperty.status !== PropertyStatusType.New;
  }

  purchasingEntityChange($event) {
    if ($event) {
      const selectedOption = this.selectedEntityOptions.filter(entityOptions => {
        return entityOptions.id == $event;
      });

      this.proformaPanel.onPurchasingEntityChange(true, selectedOption);
      return;
    }

    this.theForm.get('purchasingEntity').setValue($event);
    this.currentUwProforma.proforma['purchasingEntity'] = $event;
    this.currentUwProforma.userInputProforma['purchasingEntity'] = $event;
  }

  compsLoaded(id) {
    this.finishedLoading.emit(this.aoListingId);
  }


  /**
   *
   * @param updateRequest
   * @returns
   */
  applyUpdate(updateRequest) {
    if (updateRequest) {
      this.isRequestUpdating = true;
      this.checkConcessionFieldState();
      this.proformaPanel.initialize(this.currentProperty, this.currentAvmProforma, this.currentUwProforma, this.currentViewModel.updateRequests, false, this.avmProformaVersioning);

      if (this.proformaPanel.theForm.contains(this.currentViewModel.updateRequests.field)) {
        this.proformaPanel.theForm.get(this.currentViewModel.updateRequests.field).setValue(this.currentViewModel.updateRequests.value, { emitEvent: false });
        this.proformaPanel.onValueChanges(this.currentViewModel.updateRequests.field, this.currentViewModel.updateRequests.value);
          this.isRequestUpdating = false;
          setTimeout(()=> {
            this.proformaPanel.scrollAndFocusToFields(null,false, this.currentViewModel.updateRequests.field);
          }, 300);
      } else {
        this.isRequestUpdating = false;
      }
      return;
    }
  }


  // /**
  //  *
  //  */
  checkConcessionFieldState(restore?: boolean) {
    if(!this.updateRequests || !this.updateRequests?.activeStep?.commands?.panel?.triggerConcessionFields?.active) {
      return;
    }
    // const filled = this.proformaConfig.statusFields.some(sfield => !!this.currentUwProforma.proforma[sfield.id]);
    this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.active = true;
    if(restore &&  !_.isNil(this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.previousAction) ) {
      setTimeout(() => {
        this.triggerConcessionFields(this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.previousAction);
      }, 300);
      return;
    }

    // TODO: async events
    setTimeout(() => {
        const isConcessionVisible = this.proformaConfig.statusFields.some((sfield) =>  this.proformaPanel.theForm.contains(sfield.id));
        if(!isConcessionVisible && [CONCESSION_ACTION.UPDATE, CONCESSION_ACTION.ADD].includes(this.updateRequests.activeStep.commands.panel.triggerConcessionFields.action)) {
          this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.nextAction = 1;
          this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.actionName = 'Add concession';
        } else if ([CONCESSION_ACTION.UPDATE, CONCESSION_ACTION.DELETE].includes(this.updateRequests.activeStep.commands.panel.triggerConcessionFields.action)) {
          this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.nextAction = 0;
          this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.actionName = 'Remove concession';
        } else {
          this.updateRequests.activeStep.commands.panel.triggerConcessionFields.active = false;
        }
      }, 200);

  }

  /**
   *
   * @param action
   * @returns
   */
   triggerConcessionFields(action) {
    if (!this.updateRequests?.activeStep?.commands?.panel?.triggerConcessionFields?.commands?.active) {
      return;
    }
    if (action && ![CONCESSION_ACTION.DELETE].includes(this.updateRequests.activeStep.commands.panel.triggerConcessionFields.action)) {
      this.proformaPanel.triggerStatusFields(action);
      this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.previousAction = 1;
      this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.nextAction = 0;
      this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.actionName = 'Remove concession';
      this.unlockFieldsToEdit(this.proformaPanel.extraFields);
      return;
    }
    if ([CONCESSION_ACTION.UPDATE, CONCESSION_ACTION.ADD].includes(this.updateRequests.activeStep.commands.panel.triggerConcessionFields.action)) {
      this.proformaPanel.triggerStatusFields(action);
      this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.nextAction = 1;
      this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.previousAction = 0;
      this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.actionName = 'Add concession';
      return;
    }
    this.updateRequests.activeStep.commands.panel.triggerConcessionFields.commands.active = false;

  }

/**
 *
 * @param $event
 * @returns
 */
 checkForEventBusRequestEvent($event): string[] {
  const evBus = [];
  const actionId =this.getRequestedAction($event);
    if (actionId) {
      const triggerEVBusEvent = this.updateRequests?.activeStep?.actions?.[actionId]?.triggerEVBusEvent;
    this.checkForEvBusEvents(triggerEVBusEvent, $event, evBus);
    }

  // check for concession event
  // TODO:
  // const triggerEVBusEventForConcession = this.updateRequests?.activeStep?.commands?.panel?.triggerConcessionFields?.triggerEVBusEvent;
  // this.checkForEvBusEvents(triggerEVBusEventForConcession, $event, evBus);

  return evBus.length > 0? evBus : null;
}



  private checkForEvBusEvents(triggerEVBusEvent: any, $event: any, evBus: any[]) {
    if (triggerEVBusEvent && triggerEVBusEvent.active && Array.isArray(triggerEVBusEvent.eventBus)) {
      triggerEVBusEvent.eventBus.forEach(eventBusItem => {
        let valid = true;
        if (eventBusItem.active) {
          if (Array.isArray(eventBusItem.validators)) {
            valid = eventBusItem.validators.every(vd => {
              return !!vd(this, $event);
            });
          }

          if (valid) {
            evBus.push(eventBusItem.evbusEventName);
          }
        }
      });
    }
  }

/**
 *
 * @param $event : DRAFT or Send To TM
 * @param versioning version to set
 * @returns approuval and versioning
 * @descrition
 */

private async checkForNewStatusAndVersioningForUpdateRequestII($event: any, versioning) {
  let approvalResult = {
    notImplemented: false,
    approval: null,
    versioning
  };

  const requestedActionName = this.getRequestedAction($event);
  if(!requestedActionName || !this.updateRequests.activeStep.actions[requestedActionName]) {
    return {
      notImplemented: true
    };
  }

    if (this.updateRequests.activeStep.actions[requestedActionName].active) {
      if (this.updateRequests.activeStep.actions[requestedActionName]?.approval?.active) {
        approvalResult = await this.triggerSendToInvestorApproval(this.updateRequests.activeStep.actions[requestedActionName].approval);
        if (approvalResult.approval == UNDERWRITER_ACTIONS.CANCEL) {
          return {
            approval: approvalResult,
            versioning
          };
        }
      }
      this.checkforApprovalUpdate(requestedActionName);
      if (this.updateRequests.activeStep.actions[requestedActionName].updateStatus && this.updateRequests.activeStep.actions[requestedActionName].updateStatus.active) {
        if (approvalResult.approval == UNDERWRITER_ACTIONS.NO) {
          if (this.updateRequests.activeStep.actions[requestedActionName].updateStatus.fallBackStatus) {
            this.currentUwProforma.listingStatus = this.updateRequests.activeStep.actions[requestedActionName].updateStatus.fallBackStatus;
          }
        }
        if (approvalResult.approval === true || !this.updateRequests.activeStep.actions[requestedActionName]?.approval?.active) {
          if (!this.updateRequests.activeStep.actions[requestedActionName].updateStatus.validators) {
            this.currentUwProforma.listingStatus = this.updateRequests.activeStep.actions[requestedActionName].updateStatus.status;
          } else {
            const canUpdateStatus = this.updateRequests.activeStep.actions[requestedActionName].updateStatus.validators.find(validatorSingleStatus => {
              if(!validatorSingleStatus.rules) {
                return true;
              }
              return  validatorSingleStatus.rules.every( rl => {
                return rl(this, $event);
              });
            });
            if (canUpdateStatus && !_.isNil(canUpdateStatus.status)) {
              this.currentUwProforma.listingStatus = canUpdateStatus.status;
            } else if (this.updateRequests.activeStep.actions[requestedActionName].updateStatus.fallBackStatus) {
              this.currentUwProforma.listingStatus = this.updateRequests.activeStep.actions[requestedActionName].updateStatus.fallBackStatus;
            }
          }

        }
      }

      if (this.updateRequests.activeStep.actions[requestedActionName].versioning.active) {
        let valid = true;
        if (this.updateRequests.activeStep.actions[requestedActionName].versioning.validators) {
          valid = this.updateRequests.activeStep.actions[requestedActionName].versioning.validators.every(vd => {
            return !! vd(this, $event);
          });
        }
        versioning = valid ? this.checkForUpdateOrNewVersion(this.updateRequests,requestedActionName) || versioning : versioning;
      }
    }

  return {
    approval: approvalResult,
    versioning
  };
}

getRequestedAction($event: string) {
  if (!$event) {
    return null;
  }
  if(this.updateRequests.activeStep?.commands?.underwriter?.options) {
    return this.updateRequests.activeStep?.commands?.underwriter?.options[$event]?.actionID;
  }
  return this.selectOptions.filter(so => {
    return so.id == $event;
      }
    ).map(op => op.actionID);
}

/**
 *
 * @param versioningSetting : version structure {active udate and versionName, validators}
 * @returns void
 * @description: create new version depending on states and validators
 */
 private checkForUpdateOrNewVersion(updateRequests: IUpdateRequest, requestedActionName: string) {
  if(this.versionNames.includes( updateRequests.activeStep.actions[requestedActionName].versioning.versionName)) {
    if( !updateRequests.activeStep.actions[requestedActionName].versioning.update) {
      updateRequests.activeStep.actions[requestedActionName].versioning.update = true;
    }
  } else if (!updateRequests.activeStep.actions[requestedActionName].versioning.update) {
    return null;
  }
  return {
    versionName: updateRequests.activeStep.actions[requestedActionName].versioning.versionName,
    update: updateRequests.activeStep.actions[requestedActionName].versioning.update
  };
}

/**
 *
 * @param $event : for now we UPDATE update | UPDATE | CONCESSION_TRIGGER
 * @returns : void
 * @description:  handle request coming from updateRequest panel
 *
 */
  onChangeRequested($event) {
    if (!$event) {
      return;
    }

    if ($event.event == STEP_ACTION.CONCESSION_TRIGGER) {
      if(this.currentTabIdx !== 0) {
        this.onShowTabClick(0);
      }
      this.triggerConcessionFields(this.updateRequests?.activeStep?.commands?.panel?.triggerConcessionFields?.commands?.nextAction);
      return;
    }

    if ($event.event == STEP_ACTION.UPDATE) {
      if(this.currentTabIdx !== 0) {
        this.onShowTabClick(0);
      }
      this.updateRequests.activeStep.proforma.lockProforma = false;
      this.updateRequests.activeStep.proforma.updateProforma = false;
      this.updateRequests.activeStep.proforma.forceUpdate = true;
      this.applyUpdate(true);
      this.unlockFieldsToEdit();
      return;
    }
    if ($event.event == STEP_ACTION.APPROVAL) {
      if(this.updateRequests.activeStep.commands.panel.preApproval.action === APPROVAL_ACTIONS.ADD) {
        this.currentUwProforma.proforma.investorApproval = 1;
        if([APPROVAL_ACTIONS.REMOVE, APPROVAL_ACTIONS.TRIGGER].includes(this.updateRequests.activeStep.commands.panel.preApproval.availableActions)) {
          this.updateRequests.activeStep.commands.panel.preApproval.action = APPROVAL_ACTIONS.REMOVE;
        } else {
          this.updateRequests.activeStep.commands.panel.preApproval.active = false;
        }
      } else if (this.updateRequests.activeStep.commands.panel.preApproval.action === APPROVAL_ACTIONS.REMOVE) {
        this.currentUwProforma.proforma.investorApproval = 0;
        if([APPROVAL_ACTIONS.ADD, APPROVAL_ACTIONS.TRIGGER].includes(this.updateRequests.activeStep.commands.panel.preApproval.availableActions)) {
          this.updateRequests.activeStep.commands.panel.preApproval.action = APPROVAL_ACTIONS.ADD;
        } else {
          this.updateRequests.activeStep.commands.panel.preApproval.active = false;
        }
      }
      this.onProformaChanges();
      return;
    }
  }

  initUnderwriterNote(isFirstLoading?) {
    let concession= false;
    let preInspection = false;
    if (!this.currentUwProforma?.workFlowNote) {
      this.currentUwProforma.workFlowNote = {
        type: 'comment',
        oldValue: null,
        newValue: null
      };
    }
    if (this.currentUwProforma?.originalProforma || this.currentUwProforma?.proforma) {
      if ([
        PropertyStatusType.InspectionReview,
        PropertyStatusType.InvestorApprovalUrgent,
        PropertyStatusType.InvestorApprovalUpdated,
      ].includes(this.currentProperty?.status)) {
        concession= _.some([PROFORMA_FIELDS_IDS.RECOMMENDED_CONCESSION, PROFORMA_FIELDS_IDS.MINIMUM_ACCEPTABLE_CONCESSION], (cs) => {
          return !!this.currentUwProforma?.originalProforma?.[cs] || !!this.currentUwProforma?.proforma?.[cs];
        });
      }

      if ([
        PropertyStatusType.PreInspectionReview,
        PropertyStatusType.PreInvestorApprovalUpdated,
      ].includes(this.currentProperty?.status)) {
        preInspection = true;
      }


      if (concession || preInspection) {
        this.underwriterNote.model.newValue = this.currentUwProforma?.workFlowNote?.newValue || this.currentUwProforma?.messageOnSave;
      }

      if([
        PropertyStatusType.InvestorApprovalUpdated,
        PropertyStatusType.InvestorApprovalReady,
        PropertyStatusType.PreInvestorApprovalUpdated,
        PropertyStatusType.PreInvestorApprovalReady
      ].includes(this.currentProperty.status)) {
          this.underwriterNote.disabled = true;
        } else {
          this.underwriterNote.disabled = false;
        }

      this.underwriterNote = {...this.underwriterNote};
    }

    this.onConcessionConfigChanges({concessionChanges: concession, preInspection});
    if ( this.underwriterNote.type == 'comment') {
      this.underwriterNote.model.newValue = this.currentUwProforma?.messageOnSave || isFirstLoading? null : this.currentUwProforma?.workFlowNote?.newValue;
    }
    this.currentViewModel.underwriterNote = this.underwriterNote = {...this.underwriterNote};
  }

  onConcessionConfigChanges(setConcession) {
    const noteConf = _.cloneDeep(this.underwriterNote);
    if (setConcession.concessionChanges) {
      noteConf.type = this.currentUwProforma.workFlowNote.type = 'concession';
      noteConf.required = true;
      noteConf.placeHolder = 'Add concession notes';
      noteConf.hasError= false;
      noteConf.model.newValue = this.currentUwProforma?.workFlowNote?.newValue;
      this.currentUwProforma.messageOnSave= null;
    } else if (setConcession.preInspection || noteConf.type === 'preInspection') {
      noteConf.type = this.currentUwProforma.workFlowNote.type= 'preInspection';
      noteConf.required = false;
      noteConf.placeHolder = 'Add pre-inspection comment (optional)';
      noteConf.hasError= false;
      noteConf.model.newValue = this.currentUwProforma?.workFlowNote?.newValue;
      this.currentUwProforma.messageOnSave= null;
    } else {
      noteConf.required= false;
      noteConf.placeHolder= 'Add comment (optional)';
      noteConf.type = this.currentUwProforma.workFlowNote.type = 'comment';
      noteConf.hasError= false;
      noteConf.model.newValue = this.currentUwProforma?.messageOnSave;
      this.currentUwProforma.workFlowNote.newValue = null;
    }
    this.underwriterNote = {...noteConf};
  }


  noteChanges($event) {
    if (this.underwriterRejectionReasons) {
      this.underwriterRejectionReasons.reasonMode = false;
    }
    this.underwriterNote.model.newValue =$event;
    if (!this.currentUwProforma) {
      return;
    }
    if (['concession', 'preInspection'].includes(this.underwriterNote.type)) {
      this.currentUwProforma.workFlowNote.newValue =$event;
    } else {
      this.currentUwProforma.messageOnSave =$event;
    }

    this.currentViewModel.underwriterNote = this.underwriterNote;
    this.currentViewModel.underwriterNote.model.oldValue = this.currentUwProforma?.workFlowNote?.newValue;
    this.refreshOptionStats();
  }

  unlockFieldsToEdit(proformaFields?: IProformaRow[]) {
    const proformaRequestConf = this.updateRequests?.activeStep?.proforma;
    if(proformaRequestConf && proformaRequestConf.editLocked &&  proformaRequestConf.fieldsToEdit) {
      this.proformaPanel.unlockFields(proformaRequestConf.fieldsToEdit || [], proformaFields);
    }
  }

  onProformaHistoryExpanded($event) {
    if (this.underwriterContentClass != 'underwriterContent-' + $event) {
      this.activeProformaunderwriterContentClass = this.underwriterContentClass = 'underwriterContent-' + $event;
    }

    if (this.closedClass != 'closed ' + $event) {
      this. activeProformaclosedClass = this.closedClass = 'closed ' + $event;
    }
  }

  onUpdateRequested($event) {
      this.updateRequests.activeStep.proforma.lockProforma = false;
      this.updateRequests.activeStep.proforma.updateProforma = false;
      this.updateRequests.activeStep.proforma.forceUpdate = true;

      this.isRequestUpdating = true;

      if (this.proformaPanel.theForm.contains(this.currentViewModel.updateRequests.field)) {
        this.proformaPanel.initialize(this.currentProperty, this.currentAvmProforma, this.currentUwProforma, this.currentViewModel.updateRequests, false, this.avmProformaVersioning);
        this.proformaPanel.theForm.get($event.key).setValue($event.value, { emitEvent: false });
        if($event.key != this.currentViewModel.updateRequests.field) {
          this.proformaPanel.theForm.get(this.currentViewModel.updateRequests.field).setValue(this.currentViewModel.updateRequests.value, { emitEvent: false });
        }
        this.proformaPanel.onValueChanges(this.currentViewModel.updateRequests.field, null);
        this.proformaPanel.scrollAndFocusToFields(null,false, this.currentViewModel.updateRequests.field);
      }

      const  updateRequestsCopy = _.cloneDeep(this.updateRequests);
      this.updateRequests = updateRequestsCopy;
      this.isRequestUpdating = false;
      return;

  }

  onProformaScroll($event, tabId?) {
    if (!this.enableAddressScrolling || this.currentTabIdx != this.uwPanels.PROFORMA_PANEL) {
      return;
    }

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    const tab = tabId || this.currentTabIdx;
    this.scrollTimeout = setTimeout(() => {
      if ($event) {
        this.scrollPosition[tab] = $event.target.scrollTop;
      }

      if (!isNaN(this.scrollPosition[tab])) {
        this.isAddressVisible = this.scrollPosition[tab] < this.addressScrollingThreshold;
      } else {
        this.isAddressVisible = true;
      }
    }, this.animationsDuration);
  }

  onExternalLinkClick($event: MouseEvent, link: string) {
    if (!link) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  triggerUpdatePropertyPopup(aoListingID?) {
    const toastrOptions = {
      disableTimeOut: true,
      closeButton: true,
      enableHtml: true,
      messageClass: 'toast-message a1-toast-message',
      positionClass: "duplicate-sub",
      toastClass: 'duplicate-sub-toast ngx-toastr'
    };
    if (!this.updateToast && this.currentUwProforma.onHold) {
      this.updateToast = this.toastr.info('<div>Update available</div><div class="a1-toaster-action">Reload property</div>', null, toastrOptions);
      this.updateToast.onTap.subscribe(() => {
        this.updateToast = null;
        this.currentUwProforma.onHold = null;
        this.saveFailed = false;
        this.reloadProperty(aoListingID || this.currentProperty.AOListingID);
      });
    } else {
      this.removeToast();
    }
  }

  private removeToast() {
    if (this.updateToast) {
      this.toastr.remove(this.updateToast.toastId);
      this.toastr.clear();
      this.updateToast = null;
    }
    if (this.errorToast) {
      this.toastr.remove(this.errorToast.toastId);
      this.toastr.clear();
      this.errorToast = null;
    }
  }

  onUpdateDate($event) {
    if (Number.isNaN(Date.parse($event))) {
      return;
    }
    this.currentProperty.StatusUpdateDate = $event;
  }

  async fetchDuplicates(AOPropertyID, fetchTMUser = false) {
    return this.underwriterService.getListings(
      {
        selectedColumns: null,
        viewMode: this.viewMode,
        buyBoxId: null,
        groupKeys: [AOPropertyID],
        dateRange: null,
        viewSetting: null,
        fromLine: 0,
        filters: null,
        sort: null,
        update: false,
        fetchTMUser: fetchTMUser
      }
    );

    // return data.listings;
  }

  async handleDuplicateListingsSubmission() {
    this.isBusy = true;
    this.duplicateListings = (await this.fetchDuplicates(this.currentProperty.AOPropertyID, true))?.listings;
    const offerReadyDuplicated = this.duplicateListings.filter(listing =>
      listing.AOListingID != this.currentProperty.AOListingID &&
      listing.underwritingStatus >= PropertyStatusType.OfferReady
    );

    if (offerReadyDuplicated.length) {
      const userDecision = await this.triggerDuplicatePropertySent(offerReadyDuplicated[0].sentToTMUser, offerReadyDuplicated[0].sentToTMDate).catch(err => {
        return false;
      });

      if (!userDecision) {
        this.isBusy = false;
        return false;
      }
    }

    return true;
  }

  updateUnderwriterOptions() {
    if(!this.updateRequests || _.isEmpty(this.updateRequests?.activeStep?.commands?.underwriter?.options || {})) {
      return;
    }
    this.selectOptions = [];
    this.mainAction = [];
    if(this.updateRequests.activeStep?.commands?.underwriter?.builder && this.currentProperty) {
      const isShowAction = this.updateRequests.activeStep.commands.underwriter.builder(this, this.updateRequests.activeStep?.commands?.underwriter?.options);
      if (isShowAction || this.currentProperty.underwritingStatus ===PropertyStatusType.Hidden) {
        // TODO: edit locked for show action
        // this.updateRequests.activeStep.activeAction.saveDraft = false;
        // this.updateRequests.activeStep.actions.saveDraft.active = false;
        // this.updateRequests.activeStep.proforma.editLocked = false;
        this.updateRequests.activeStep.activeAction.sendTM = true;

      }
    }

    if (!!this.updateRequests?.activeStep?.commands?.underwriter?.options) {
      for (const op in this.updateRequests?.activeStep?.commands?.underwriter?.options) {
       if (op && !_.isEmpty(op)) {
        this.selectOptions.push(this.updateRequests?.activeStep?.commands?.underwriter?.options[op]);
        if(this.updateRequests?.activeStep?.commands?.underwriter?.options[op].main) {
          this.mainAction.push(this.updateRequests?.activeStep?.commands?.underwriter?.options[op]);
        }
       }
      }
    }
  }

  private updateHideForAllDisabledII() {
    const hideOption = (this.selectOptions.concat(this.mainAction)).filter(op => {
      return op.id ==UNDERWRITER_ACTIONS.HIDE;
    })[0] || this.mainAction.filter(op => {
      return op.id == UNDERWRITER_ACTIONS.HIDE;
    })[0];

    if (!hideOption) {
      return;
    }

    if(this.updateRequests && this.updateRequests?.activeStep?.DisableHideForEveryOne) {
      hideOption.disabled = true;
      return;
    }

    hideOption.disabled = [
      PropertyStatusType.Counter,
      PropertyStatusType.ConcessionToUW,
      PropertyStatusType.NewPrice,
      PropertyStatusType.OfferReady,
      PropertyStatusType.OfferReadyReason,
      PropertyStatusType.OfferReadyUrgent,
    ].includes(this.currentProperty?.underwritingStatus) || (
      this.currentProperty?.underwritingStatus !== PropertyStatusType.Underwritten &&
      !this.canSaveComparables() &&
      (
        !this.isUnderwritterDirty() &&
        !this.proformaUpdated
      )
    );
  }

  private sendToTransactionManagerDisabled(isNotValid) {
    const sendToTMOption = (this.selectOptions.concat(this.mainAction)).filter(op => {
      return op.id == UNDERWRITER_ACTIONS.READY;
    })[0]|| this.mainAction.filter(op => {
      return op.id == UNDERWRITER_ACTIONS.READY;
    })[0];
    if (!sendToTMOption) {
      return;
    }

    if (this.updateRequests && !this.updateRequests?.activeStep?.actions.sendTM.active) {
      sendToTMOption.disabled = true;
      return;
    }

    if (
      (this.isUnderwritterDirty()? isNotValid == VALIDATION_ERROR_TYPE.NONE : true)
      &&
      this.updateRequests && this.updateRequests?.activeStep?.activeAction.sendTM
      ) {
      sendToTMOption.disabled = false;
      return;
    }

    sendToTMOption.disabled = ((this.isUnderwritterDirty() || isNotValid)? ![VALIDATION_ERROR_TYPE.NONE, VALIDATION_ERROR_TYPE.WARN].includes(isNotValid) : false)
      || (this.currentProperty?.underwritingStatus !== PropertyStatusType.Underwritten &&
        !this.canSaveComparables() &&
        (
          !this.isUnderwritterDirty() &&
          !this.proformaUpdated
        ));

  }

  private sendForApprovalDisabled(isNotValid) {
    const sendToTMOption = (this.selectOptions.concat(this.mainAction)).filter(op => {
      return op.id == UNDERWRITER_ACTIONS.APPROVAL;
    })[0]|| this.mainAction.filter(op => {
      return op.id == UNDERWRITER_ACTIONS.APPROVAL;
    })[0];
    if (!sendToTMOption) {
      return;
    }

    if (this.updateRequests && this.updateRequests?.activeStep?.activeAction.submitApproval) {
      sendToTMOption.disabled = false;
      return;
    }
    if (this.updateRequests && !this.updateRequests?.activeStep?.actions?.submitApproval?.active) {
      sendToTMOption.disabled = true;
      return;
    }

    sendToTMOption.disabled = ((this.isUnderwritterDirty() || isNotValid)? ![VALIDATION_ERROR_TYPE.NONE, VALIDATION_ERROR_TYPE.WARN].includes(isNotValid) : false)
    || (this.currentProperty?.underwritingStatus !== PropertyStatusType.Underwritten &&
      !this.canSaveComparables() &&
      (
        !this.isUnderwritterDirty() &&
        !this.proformaUpdated
      ));
  }

  isDraftDisabled(isNotValid) {
    let saveDraftOption;
    saveDraftOption = this.selectOptions.filter(op => {
      return op.id == UNDERWRITER_ACTIONS.DRAFT;
    })[0] || this.mainAction.filter(op => {
      return op.id == UNDERWRITER_ACTIONS.DRAFT;
    })[0];

    if (!saveDraftOption) {
      return;
    }

    if (this.currentProperty.underwritingStatus === PropertyStatusType.Hidden) {
      saveDraftOption.disabled = true;
      return;
    }

    if(this.updateRequests && this.updateRequests?.activeStep?.activeAction.saveDraft) {
      saveDraftOption.disabled = false;
      return;
    }

    if(this.updateRequests && !this.updateRequests?.activeStep?.actions.saveDraft.active) {
      saveDraftOption.disabled = true;
      return;
    }

    saveDraftOption.disabled = ((this.isUnderwritterDirty() || isNotValid)? ![VALIDATION_ERROR_TYPE.NONE, VALIDATION_ERROR_TYPE.WARN].includes(isNotValid) : false) || !this.canSaveComparables() &&
    (
      !this.currentProperty ||
      this.isBusy ||
      (!this.isUnderwritterDirty() && !this.canSaveRejectionReason() && !this.canSaveComment())
      );
  }

  isShowForEveryoneDisabled() {
    const showOption = (this.selectOptions.concat(this.mainAction)).filter(op => {
      return op.id == UNDERWRITER_ACTIONS.SHOW;
    })[0] || this.mainAction.filter(op => {
      return op.id == UNDERWRITER_ACTIONS.SHOW;
    })[0];

    if (!showOption) {
      return;
    }
    if(this.updateRequests && this.updateRequests?.activeStep?.activeAction.show) {
      showOption.disabled = false;
      return;
    }
    showOption.disabled = this.currentProperty.underwritingStatus != PropertyStatusType.Hidden;
  }





  refreshOptionStats() {
    const { isNotValid } = this.proformaPanel.checkForMissingRequiredFields();
    this.isDraftDisabled(isNotValid);
    this.updateHideForAllDisabledII();
    this.isShowForEveryoneDisabled();
    this.sendToTransactionManagerDisabled(isNotValid);
    this.sendForApprovalDisabled(isNotValid);

    this.optionalActionDisabled = !(this.selectOptions || []).filter(so => {
      return !so.main;
    })
    .some(so => {
        return so.disabled == false;
    }) || (isNotValid === VALIDATION_ERROR_TYPE.REQUIRED);

    this.mainActionDisabled = this.mainAction[0]? this.mainAction[0].disabled : this.mainActionDisabled;
  }

  onProformaChanges($options?) {
    if ($options) {
      const keys = Object.keys($options);
      keys.forEach(key => {
        this.currentUwProforma.proforma[key] = $options[key];
      });
    }

    if (this.enableInterimBBC) {
      this.calculateClassification(this.currentUwProforma.proforma);
    }
    this.selectedEntityOptions = this.approvalService.updateSelectedEntityOptions( this.currentUwProforma.proforma);
    this.proformaChangesSubject.next(true);
  }

  resetProformaChanges() {
    const aolistingId = this.currentUwProforma.proforma.AOListingID;
    this.currentUwProforma.proforma = !_.isNil(this.currentUwProforma.originalProforma) ? this.currentUwProforma.originalProforma : new ProForma();
    this.currentUwProforma.proforma.AOListingID = aolistingId;
    this.currentUwProforma.selectedSaleComparableIds = (this.currentViewModel?.originalSelectedComps?.sale || []).map(rentIds=> {
      return rentIds.toString();
    });

    this.currentUwProforma.selectedRentComparableIds = (this.currentViewModel?.originalSelectedComps?.rent || []).map(rentIds=> {
      return rentIds.toString();
    });

    this.currentUwProforma.selectedActiveComparableIds = (this.currentViewModel?.originalSelectedComps?.active || []).map(rentIds=> {
      return rentIds.toString();
    });

    this.currentUwProforma.selectedComparableIds = [...this.currentUwProforma.selectedActiveComparableIds, ...this.currentUwProforma.selectedSaleComparableIds, ... this.currentUwProforma.selectedRentComparableIds];
    this.comparablesPanel.resetRowSelection();
    this.currentUwProforma.isDirty = false;
    this.currentUwProforma.userInputProforma = this.currentUwProforma.originalUserInputProforma? this.currentUwProforma.originalUserInputProforma: this.currentUwProforma.userInputProforma;
  }

  checkforApprovalUpdate(requestedActionName: any) {
    const selectedPurchasingEntity = this.currentUwProforma?.proforma?.purchasingEntity;
    const entity = (this.selectedEntityOptions || []).filter(peo => peo.label == selectedPurchasingEntity)[0];

    if (entity && entity.autoApproval) {
      this.currentUwProforma.proforma.investorApproval = InvestorApprovalType.autoApproval;
      return;
    }
    if (requestedActionName == UNDERWRITER_COMMANDS_NAME.submitApproval) {
      this.currentUwProforma.proforma.investorApproval = InvestorApprovalType.pendingApproval;
      return;
    }
    if (requestedActionName == UNDERWRITER_COMMANDS_NAME.undoApproval) {
      this.currentUwProforma.proforma.investorApproval = InvestorApprovalType.autoAssign;
    }
  }

  async calculateClassification(proforma: ProForma) {
    try {
      const offerReadyStatus = PropertyStatusType.OfferReady;
      if (this.currentProperty.status < offerReadyStatus) {
        const apiResult = await this.apiService.getClassification(proforma.AOListingID, proforma.monthlyRentAmount || 0);
        proforma.interimBuyBoxClassification = CLASSIFICATIONS_LABELS[(apiResult.classification).toLowerCase()] || 'Error';
        this.currentProperty.underwritingClassificationClass = ClassificationCategoryTypeFromStatus(this.currentProperty, proforma.interimBuyBoxClassification);
        ClassificationToolTipSetup(this.currentProperty, proforma.interimBuyBoxClassification);
      }
    } catch (error) {
      console.error('Something went wrong during Classification', error);
    }
  }

  isPropertyRejectable(currentConf?) {
    const config: IRejectionComment =  {
      reasonMode: false,
      active: false,
      disabled: false,
      updated: false,
      reason: {
        oldValue: null,
        newValue: null
      }
      ,hasError: false
    };
    if ( [PropertyStatusType.New, PropertyStatusType.Updated, PropertyStatusType.Underwritten].includes(this.currentProperty.underwritingStatus) ) {
      config.active = true;
      config.disabled = false;
      config.reason.oldValue = this.currentProperty.rejectionReasons;
      if (currentConf) {
        config.updated = currentConf.updated;
        config.reason.newValue = currentConf.newValue;
      }
      if (config.reason.oldValue) {
        config.reasonMode = true;
      }
      return config;
    }
    return null;
  }







  rejectionReasonsChange($event: IRejectionComment) {
    if ($event) {
      this.underwriterRejectionReasons.hasError = false;
      this.underwriterRejectionReasons.updated = $event.updated;
      this.underwriterRejectionReasons.reason.newValue = $event.reason.newValue.length? $event.reason.newValue.join(' \\ ') : null;
      this.underwriterRejectionReasons.reasonMode = $event.reasonMode;
    }
    this.missingRejectionReasons = false;
    this.currentViewModel.underwriterRejectionReasons = this.underwriterRejectionReasons;
    this.refreshOptionStats();
  }

  canSaveRejectionReason() {
    return !!(this.underwriterRejectionReasons?.active && this.underwriterRejectionReasons?.updated);
  }

  canSaveComment() {
    return this.underwriterNote && (this.underwriterNote.model.newValue != this.underwriterNote.model.oldValue) && ![PropertyStatusType.Hidden, PropertyStatusType.PreInvestorApprovalUpdated, PropertyStatusType.InvestorApprovalUpdated].includes(this.currentProperty.status);
  }

  checkRejectionCommentErrors(closeClick = true) {
    if (
      this.underwriterRejectionReasons
      && this.underwriterRejectionReasons.active
      && this.underwriterRejectionReasons.reasonMode
      && (!this.underwriterRejectionReasons.reason.newValue || !this.underwriterRejectionReasons.reason.newValue.length)
      && (!this.underwriterRejectionReasons.reason.oldValue || !this.underwriterRejectionReasons.reason.oldValue.length)
      ) {
        this.underwriterRejectionReasons.hasError = true;
        this.underwriterRejectionReasons = {...this.underwriterRejectionReasons};
        this.missingRejectionReasons = !closeClick ? true: false;
        return true;
    }
    if (closeClick && this.underwriterRejectionReasons && this.underwriterRejectionReasons.updated) {
      this.underwriterRejectionReasons.hasError = true;
        this.underwriterRejectionReasons = {...this.underwriterRejectionReasons};
        return true;
    }

    if (closeClick && this.underwriterNote && (this.underwriterNote.model.newValue != this.underwriterNote.model.oldValue)) {
      this.underwriterNote.hasError = true;
      return true;
    }
    return false;
  }

  handleUnsavedChanges(vm: UnderwriterViewModel) {
    if ((vm.uwProforma.isDirty && !vm.uwProforma.automaticUpdate)
    || vm.isDirty
    || this.hasCompareChanged) {
      this.proformaPanel.checkForMissingRequiredFields();
    }
    this.checkRejectionCommentErrors(true);
  }

  checkIfCanSendToTM($event) {
    if([UNDERWRITER_ACTIONS.CONTINUE, UNDERWRITER_ACTIONS.READY].includes($event) || this.currentUwProforma.sendToTransactionManager) {
      if (this.underwriterRejectionReasons && this.underwriterRejectionReasons.reasonMode) {
        this.underwriterRejectionReasons.hasError = true;
        this.missingRejectionReasons = true;
        this.underwriterRejectionReasons = {...this.underwriterRejectionReasons};
        return false;
      }
    }
    return true;
  }

}
